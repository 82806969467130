import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../services/constants";
import Footer from "../../component/footer";
import Header from "../../component/header";

const EmailVerification = () => {
  return (
    <>
      <Header />
      <EmailVerificationPage />
      <Footer />
    </>
  );
};

const EmailVerificationPage = () => {
  const [email, setEmail] = useState("");
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const onEmail = (e) => setEmail(e.target.value);

  const history = useNavigate();

  const onSubmit = () => {
    axios
      .post(`${BASE_URL}/user/password-reset-email/`, {
        email: email,
        redirect_url: `https:/www.ngaanepal.org/forgetpassword/setpassword`,
      })
      .then((res) => {
        setSuccesMessege("Reset Link is Send to Your Email.");
        setTimeout(() => {
          history("/login");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur While Reseting Your Password.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };
  return (
    <Box className="signinmargin">
      <Grid
        item
        md={6}
        xs={12}
        style={{ margin: "0 auto" }}
        className="signinauto"
      >
        <Grid container>
          <Grid item md={6} xs={12} className="signinleft" style={{textAlign:"start"}}>
            <Box>
              <Typography className="signintext">Verify Your Email</Typography>
            </Box>
            <Box className="newforms">
              <Box>
                <lable className="emailtext">Email*</lable>
                <input
                  type="email"
                  onChange={onEmail}
                  className="titleform2"
                  placeholder="Enter Your Email"
                />
              </Box>
              <button onClick={onSubmit} className="signinbutton" style={{border:"none"}}>
                Verify
              </button>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className="leftcolor display-none">
            <Box className="signinnayamargin3">
              <Typography className="accounttext">
                Already Have an Account?
              </Typography>
              <Link to="/login">
                <button className="signupnewbtn" style={{border:'none'}}>Login</button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EmailVerification;

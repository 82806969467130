import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useAddContactMutation } from "../../../../services/contactSlice";
import { useForm } from "../../../dashboard/pages/forms/useForm";

const ContactForm = () => {
  const initialFValues = {
    full_name: "",
    email: "",
    contact_no: "",
    message: "",
  };

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [addContact] = useAddContactMutation();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInput,
    resetForm,
    valueArray,
  } = useForm(initialFValues, true);

  const handleSubmit = (e) => {
    
    e.preventDefault();
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("email", values.email);
    formData.append("contact_no", values.contact_no);
    formData.append("message", values.message);
    addContact(formData)
      .then((res) => {
        setSuccesMessege("Thanks for Contacting Us.");
        setTimeout(() => {
          window.location.reload();
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <form
      className="custom-form contact-form"
      action="#"
      method="post"
      role="form"
    >
      <h2>Contact</h2>

      <p className="mb-4" style={{color:"white"}}>
        Or, you can just send an email:
        <a  href="mailto:namgyalgokarnaalumni@gmail.com">namgyalgokarnaalumni@gmail.com</a>
      </p>
      <div className="row">
        <div className="col-lg-12 col-md-6 col-12">
          <input
            type="text"
            name="full_name"
            id="full-name"
            className="form-control"
            placeholder="eg. Abc Gtm"
            required
            onChange={handleInputChange}
            value={values.full_name}
          />
        </div>
      </div>

      <input
        type="email"
        name="email"
        id="email"
        pattern="[^ @]*@[^ @]*"
        className="form-control"
        placeholder="eg. abcgtm@gmail.com"
        required
        onChange={handleInputChange}
        value={values.email}
      />
      <input
        type="number"
        name="contact_no"
        id="contact_no"
        className="form-control"
        placeholder="eg. 9702102020"
        required
        onChange={handleInputChange}
        value={values.contact_no}
      />

      <textarea
        name="message"
        rows="5"
        className="form-control"
        style={{ height: "auto" }}
        id="message"
        placeholder="What can we help you?"
        onChange={handleInputChange}
        value={values.message}
      ></textarea>

      <button type="submit" className="form-control" onClick={handleSubmit}>
        Send Message
      </button>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </form>
  );
};
export default ContactForm;

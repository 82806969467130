import React from "react";
import TextButton from "../../../templates/component/buttons/textButton";

const VisionAddButton = () =>{

    return(
        <>
            <TextButton />

        </>
    )
}

export default VisionAddButton;
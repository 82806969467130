import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardHome from "../containers/dashboard/pages/dashboardhome";
import Donation from "../containers/templates/pages/donation";
import Gallery from "../containers/templates/pages/gallery";
import Home from "../containers/templates/pages/home";
import DashboardBlogs from "../containers/dashboard/pages/blogDashboard/blogDashboardHome";
import AddBlog from "../containers/dashboard/pages/blogDashboard/blogNewPage";
import BlogEditPage from "../containers/dashboard/pages/blogDashboard/blogEditPage";
import DashboardHomePages from "../containers/dashboard/pages/homePageDashboard/homePageDashboardHome";
import AddHomePage from "../containers/dashboard/pages/homePageDashboard/homePageNewPage";
import HomePageEditPage from "../containers/dashboard/pages/homePageDashboard/homePageEditPage";
import DashboardAboutPages from "../containers/dashboard/pages/aboutDashboard/aboutPageDashboardHome";
import AddAboutPage from "../containers/dashboard/pages/aboutDashboard/aboutPageNewPage";
import AboutPageEditPage from "../containers/dashboard/pages/aboutDashboard/aboutPageEditPage";
import DashboardImageGallerys from "../containers/dashboard/pages/imageGalleryDashboard/imageGalleryDashboardHome";
import AddImageGallery from "../containers/dashboard/pages/imageGalleryDashboard/imageGalleryNewPage";
import ImageGalleryEditPage from "../containers/dashboard/pages/imageGalleryDashboard/imageGalleryEditPage";
import DashboardContacts from "../containers/dashboard/pages/contactDashboard/contactDashboardHome";
import ContactEditPage from "../containers/dashboard/pages/contactDashboard/contactEditPage";

import DashboardTestimonials from "../containers/dashboard/pages/testimonialDashboard/testimonialDashboardHome";
import AddTestimonial from "../containers/dashboard/pages/testimonialDashboard/testimonialNewPage";
import TestimonialEditPage from "../containers/dashboard/pages/testimonialDashboard/testimonialEditPage";

import DashboardWorks from "../containers/dashboard/pages/worksDashboard/workDashboardHome";
import AddWork from "../containers/dashboard/pages/worksDashboard/workNewPage";
import WorkEditPage from "../containers/dashboard/pages/worksDashboard/workEditPage";

import DashboardMissions from "../containers/dashboard/pages/missionsDashboard/missionDashboardHome";
import AddMission from "../containers/dashboard/pages/missionsDashboard/missionNewPage";
import MissionEditPage from "../containers/dashboard/pages/missionsDashboard/missionEditPage";

import DashboardVisions from "../containers/dashboard/pages/visionsDashboard/visionDashboardHome";
import AddVision from "../containers/dashboard/pages/visionsDashboard/visionNewPage";
import VisionEditPage from "../containers/dashboard/pages/visionsDashboard/visionEditPage";

import DashboardDonationInformations from "../containers/dashboard/pages/donationInformationsDashboard/donationInformationDashboardHome";
import AddDonationInformation from "../containers/dashboard/pages/donationInformationsDashboard/donationInformationNewPage";
import DonationInformationEditPage from "../containers/dashboard/pages/donationInformationsDashboard/donationInformationEditPage";

import MemberEditPage from "../containers/dashboard/pages/membersDashboard/memberEditPage";

import DashboardChairpersons from "../containers/dashboard/pages/chairpersonDashboard/chairpersonDashboardHome";
import AddChairperson from "../containers/dashboard/pages/chairpersonDashboard/chairpersonNewPage";
import ChairpersonEditPage from "../containers/dashboard/pages/chairpersonDashboard/chairpersonEditPage";

import Member from "../containers/templates/pages/member";
import BlogPage from "../containers/templates/pages/blogpage";
import DashboardConstantvalues from "../containers/dashboard/pages/constantvalueDashboard/constantvalueDashboardHome";
import AddConstantvalue from "../containers/dashboard/pages/constantvalueDashboard/constantvalueNewPage";
import ConstantvalueEditPage from "../containers/dashboard/pages/constantvalueDashboard/constantvalueEditPage";
import Login from "../containers/templates/pages/login";
import Signup from "../containers/templates/pages/signup";
import Cookies from "js-cookie";
import Errors from "../containers/templates/pages/error";
import EmailVerification from "../containers/templates/pages/forgotpassword/emailVerification";
import NewPassword from "../containers/templates/pages/forgotpassword/newPassword";
import BlogDetailPage from "../containers/templates/pages/blogDetailPage";

const Router = () => {
  const Access_token = Cookies.get("access");
  const Refresh_token = Cookies.get("refresh");
  const email = Cookies.get("username");
  const is_admin = Cookies.get("is_admin");
  const TOKEN_KEY = { Access_token, Refresh_token, email, is_admin };
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (
      TOKEN_KEY["access"] == undefined &&
      TOKEN_KEY["refresh"] == undefined &&
      TOKEN_KEY["username"] == undefined &&
      TOKEN_KEY["is_admin"] == undefined
    ) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/donation" exact element={<Donation />} />
        <Route path="/become/a/member" exact element={<Member />} />
        <Route path="/blog" exact element={<BlogPage />} />
        <Route path="/blog/detail/:id" exact element={<BlogDetailPage />} />
        {isAuth === true ? null : (
          <>
            <Route path="/login" exact element={<Login />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route
              path="/forgetpassword/emailverification"
              exact
              element={<EmailVerification />}
            />
            <Route
              path="/forgetpassword/setpassword"
              exact
              element={<NewPassword />}
            />
          </>
        )}
        {isAuth == true ? (
          <>
            <Route path="/dashboard/home" exact element={<DashboardHome />} />
            <Route path="/dashboard/blogs" exact element={<DashboardBlogs />} />
            <Route path="/dashboard/new/blogs" exact element={<AddBlog />} />
            <Route
              path="/dashboard/blogs/edit/:id"
              exact
              element={<BlogEditPage />}
            ></Route>
            <Route
              path="/dashboard/home-pages"
              exact
              element={<DashboardHomePages />}
            />
            <Route
              path="/dashboard/new/home-pages"
              exact
              element={<AddHomePage />}
            />
            <Route
              path="/dashboard/home-pages/edit/:id"
              exact
              element={<HomePageEditPage />}
            ></Route>
            <Route
              path="/dashboard/about-pages"
              exact
              element={<DashboardAboutPages />}
            />
            <Route
              path="/dashboard/new/about-pages"
              exact
              element={<AddAboutPage />}
            />
            <Route
              path="/dashboard/about-pages/edit/:id"
              exact
              element={<AboutPageEditPage />}
            ></Route>
            <Route
              path="/dashboard/image-gallerys"
              exact
              element={<DashboardImageGallerys />}
            />
            <Route
              path="/dashboard/new/image-gallerys"
              exact
              element={<AddImageGallery />}
            />
            <Route
              path="/dashboard/image-gallerys/edit/:id"
              exact
              element={<ImageGalleryEditPage />}
            ></Route>
            <Route
              path="/dashboard/contact"
              exact
              element={<DashboardContacts />}
            />
            <Route
              path="/dashboard/contact/edit/:id"
              exact
              element={<ContactEditPage />}
            ></Route>

            <Route
              path="/dashboard/testimonial"
              exact
              element={<DashboardTestimonials />}
            />
            <Route
              path="/dashboard/new/testimonials"
              exact
              element={<AddTestimonial />}
            />
            <Route
              path="/dashboard/testimonials/edit/:id"
              exact
              element={<TestimonialEditPage />}
            />

            <Route path="/dashboard/work" exact element={<DashboardWorks />} />
            <Route path="/dashboard/new/works" exact element={<AddWork />} />
            <Route
              path="/dashboard/works/edit/:id"
              exact
              element={<WorkEditPage />}
            />

            <Route
              path="/dashboard/mission"
              exact
              element={<DashboardMissions />}
            />
            <Route
              path="/dashboard/new/missions"
              exact
              element={<AddMission />}
            />
            <Route
              path="/dashboard/missions/edit/:id"
              exact
              element={<MissionEditPage />}
            />

            <Route
              path="/dashboard/vision"
              exact
              element={<DashboardVisions />}
            />
            <Route
              path="/dashboard/new/visions"
              exact
              element={<AddVision />}
            />
            <Route
              path="/dashboard/visions/edit/:id"
              exact
              element={<VisionEditPage />}
            />

            <Route
              path="/dashboard/donation-information"
              exact
              element={<DashboardDonationInformations />}
            />
            <Route
              path="/dashboard/new/donation-informations"
              exact
              element={<AddDonationInformation />}
            />
            <Route
              path="/dashboard/donation-informations/edit/:id"
              exact
              element={<DonationInformationEditPage />}
            />

            <Route
              path="/dashboard/member/edit/:id"
              exact
              element={<MemberEditPage />}
            />
            <Route
              path="/dashboard/chairperson"
              exact
              element={<DashboardChairpersons />}
            />
            <Route
              path="/dashboard/new/chairpersons"
              exact
              element={<AddChairperson />}
            />
            <Route
              path="/dashboard/chairpersons/edit/:id"
              exact
              element={<ChairpersonEditPage />}
            />

            <Route
              path="/dashboard/constantvalue"
              exact
              element={<DashboardConstantvalues />}
            />
            <Route
              path="/dashboard/new/constantvalues"
              exact
              element={<AddConstantvalue />}
            />
            <Route
              path="/dashboard/constantvalues/edit/:id"
              exact
              element={<ConstantvalueEditPage />}
            />
          </>
        ) : null}
        <Route path="*" exact element={<Errors />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

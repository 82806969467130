import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { useGetBlogsByIdQuery } from "../../../services/blogSlice";
import Footer from "../component/footer";
import Header from "../component/header";

const BlogDetailPage = () => {
  return (
    <div>
      <Header />
      <BlogPage />
      <Footer />
    </div>
  );
};

const BlogPage = () => {
  const id = useParams();
  const [date, setDate] = useState("");
  const data = useGetBlogsByIdQuery(id.id);
  useEffect(()=>{
  if(data.status==='fulfilled')
  {
    let date1 = new Date(data.currentData.created_at);
    setDate(date1.toDateString());
  }
},[data])

  return (
    <div style={{ marginBottom: "2.5rem" }}>
      {data !== undefined ? (
        <>
          {data.status === "fulfilled" ? (
            <>
              <div>
                <img src={data.currentData.image} style={{ width: "100%" }} />
                <div className="col-lg-9 col-12 m-auto mt-4 pt-3 text-start res-padding">
                  <div>
                    <h1>{data.currentData.title}</h1>
                    <div>
                      <div className="news-block-info ">
                        <div className="d-flex mt-2 ">
                          <div className="news-block-date">
                            <p>
                              <i className="bi-calendar4 custom-icon me-1"></i>
                              {date}
                            </p>
                          </div>

                          <div className="news-block-author mx-5">
                            <p>
                              <i className="bi-person custom-icon me-1"></i>
                              By Admin
                            </p>
                          </div>
                        </div>

                        <div className="news-block-body">
                          <p>{data.currentData.text}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default BlogDetailPage;

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { TbBell } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const history = useNavigate();
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const Logout = (e) => {
    e.preventDefault();
    try {
      Cookies.remove("access");
      Cookies.remove("refresh");
      Cookies.remove("username");
      Cookies.remove("is_admin");
      setSuccesMessege("Admin Has Been Logout.");
      setTimeout(() => {
        history("/");
      }, [3000]);
    } catch (err) {
      
      setErrorMessege("Some Error Occur. Please Try again later.");
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };
  return (
    <Box style={{ padding: "1.5rem" }}>
      <Grid container>
        <Grid item xs={11}>
          <Typography className="dashboard-header-text">Dashboard</Typography>
        </Grid>
        <Grid item xs={1} style={{ textAlign: "end" }}>
          <Grid container spacing={0}>
            <Grid item>
              <TbBell color="white" size={20} />
            </Grid>
            <Grid item>
              <FiSettings
                color="white"
                style={{ marginLeft: "10px" }}
                size={17}
              />
            </Grid>
            <Grid item>
              <MdLogout
                color="white"
                onClick={Logout}
                style={{ marginLeft: "12px" }}
                size={18}
              />
            </Grid>
          </Grid>
        </Grid>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default Header;

import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const servicesAdapter = createEntityAdapter()

const initialState = servicesAdapter.getInitialState()

export const serviceSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getServices: builder.query({
            query: () => '/services/service-list/',
            transformResponse: responseData => {
                return servicesAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Services', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Services', id }))
            ]
        }),
        getServicesById: builder.query({
            query: (id)=>({
                url:`/services/service-list/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Services']
        }),
        addService: builder.mutation({
            query: (service) => {
            return{
                url: '/services/service-list/',
                method: 'POST',
                body: service,
            }
        },
            invalidatesTags: ['Services']
        }),
        updateService: builder.mutation({
           
            query: (updateService) => {
                return{
                url: `/services/service-list/${updateService.get('id')}/`,
                method: 'PATCH',
                body: updateService
                }
            },
            invalidatesTags: ['Services']
        }),
        deleteService: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/services/service-list/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Services']
        }),
    })
})

export const {
    useGetServicesQuery,
    useAddServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation,
    useGetServicesByIdQuery
} = serviceSlice

// returns the query result object
export const selectServicesResult = serviceSlice.endpoints.getServices.select()

// Creates memoized selector
const selectServicessData = createSelector(
    selectServicesResult,
    servicesResult => servicesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllServices,
    selectById: selectServiceById,
    selectIds: selectServiceIds
    // Pass in a selector that returns the posts slice of state
} = servicesAdapter.getSelectors(state => selectServicessData(state) ?? initialState)
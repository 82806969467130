import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from './constants'

export const apiSlice = createApi({
    reducerPath: 'api', // optional
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: [
        'Blogs',
        'HomePages',
        'AboutPages',
        'ImageGallerys',
        'Contacts',
        'Testimonials',
        'Works',
        'Missions',
        'Visions',
        "Members",
        'DonateInformations',
        'Chairpersons'],
    endpoints: builder => ({})
})
import React, { useState } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Together from "../../../static/images/news/close-up-happy-people-working-together.jpg";
import Doctor from "../../../static/images/news/africa-humanitarian-aid-doctor.jpg";
import { useGetBlogsQuery } from "../../../services/blogSlice";
import { Box, Pagination } from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";

const BlogPage = () => {
  return (
    <>
      <Header />
      <BlogPageDesign />
      <Footer />
    </>
  );
};

const BlogPageDesign = () => {
  const data = useGetBlogsQuery("getBlogs");
  const { isLoading, isSuccess } = useGetBlogsQuery("getBlogs");

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["image", "subtitle", "created_at", "Address", "Is Active"];
    tableData = [];
    data.data.ids.forEach((key) => {
      var myDate = new Date(data.data.entities[key].created_at);

      tableData.push({
        image: data.data.entities[key].image,
        subtitle: data.data.entities[key].subtitle,
        created_at: myDate.toDateString(),
        title: data.data.entities[key].title,
        text: data.data.entities[key].text,
      });
    });
  }

  const [page, setPage] = useState(1);
  const PER_PAGE = 2;

  const count = Math.ceil(tableData.length / PER_PAGE);
  const _DATA = usePagination(tableData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <main>
      <section className="news-detail-header-section text-center">
        <div className="section-overlay"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <h1 className="text-white">News and Activities</h1>
            </div>
          </div>
        </div>
      </section>
      <section
        className="news-section section-padding"
        style={{ textAlign: "start" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-12">
              {data !== undefined ? (
                <>
                  {data.status === "fulfilled" ? (
                    <>
                      {data.data.ids.map((key) => (
                        <div className="news-block">
                          <div className="news-block-top">
                            <a
                              href={`/blog/detail/${data.data.entities[key].id}`}
                            >
                              <img
                                src={data.data.entities[key].image}
                                className="news-image img-fluid1"
                                alt=""
                              />
                            </a>

                            <div className="news-category-block">
                              <a
                                href={`/blog/detail/${data.data.entities[key].id}`}
                                className="category-block-link"
                              >
                                {data.data.entities[key].subtitle}
                              </a>
                            </div>
                          </div>

                          <div className="news-block-info">
                            <div className="d-flex mt-2">
                              <div className="news-block-date">
                                <p>
                                  <i className="bi-calendar4 custom-icon me-1"></i>
                                  {data.data.entities[key].created_at}
                                </p>
                              </div>

                              <div className="news-block-author mx-5">
                                <p>
                                  <i className="bi-person custom-icon me-1"></i>
                                  By Admin
                                </p>
                              </div>
                            </div>

                            <div className="news-block-title mb-2">
                              <h4>
                                <a href={`/blog/detail/${data.data.entities[key].id}`} className="news-block-title-link1">
                                  {data.data.entities[key].title}
                                </a>
                              </h4>
                            </div>

                            <div className="news-block-body">
                              <p className="blog-content">{data.data.entities[key].text}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </>
              ) : null}
            </div>

            <div className="col-lg-4 col-12 mx-auto">
              <h5 className="mb-3">Recent news</h5>
              {data !== undefined ? (
                <>
                  {data.status === "fulfilled" ? (
                    <>
                      {data.data.ids.map((key) => (
                        <div className="news-block news-block-two-col d-flex mt-4">
                          <div className="news-block-two-col-image-wrap">
                            <a href={`/blog/detail/${data.data.entities[key].id}`}>
                              <img
                                src={data.data.entities[key].image}
                                className="news-image img-fluid1"
                                alt=""
                              />
                            </a>
                          </div>
                          <div className="news-block-two-col-info">
                            <div className="news-block-title mb-2">
                              <h6>
                                <a
                                  href={`/blog/detail/${data.data.entities[key].id}`}
                                  className="news-block-title-link1"
                                >
                                  {data.data.entities[key].title}
                                </a>
                              </h6>
                            </div>

                            <div className="news-block-date">
                              <p>
                                <i className="bi-calendar4 custom-icon me-1"></i>
                                {data.data.entities[key].created_at}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                  <Box style={{ marginTop: "12px" }}>
                    <Pagination
                      count={count}
                      size={"small"}
                      page={page}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogPage;

import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const constantvaluesAdapter = createEntityAdapter()

const initialState = constantvaluesAdapter.getInitialState()

export const constantvalueSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getConstantvalues: builder.query({
            query: ()=>({
                url:`/basic/constant-values/`,
                method: 'GET',
            }),
            providesTags: ['Constantvalues']
        }),
        getConstantvaluesById: builder.query({
            query: (id)=>({
                url:`/basic/constant-values/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Constantvalues']
        }),
        addConstantvalue: builder.mutation({
            query: (constantvalue) => {
            
            return{
                url: '/basic/constant-values/',
                method: 'POST',
                body: constantvalue
            }
        },
            invalidatesTags: ['Constantvalues']
        }),
        updateConstantvalue: builder.mutation({
           
            query: (updateConstantvalue) => {
                return{
                url: `/basic/constant-values/${updateConstantvalue.get('id')}/`,
                method: 'PATCH',
                body: updateConstantvalue
                }
            },
            invalidatesTags: ['Constantvalues']
        }),
        deleteConstantvalue: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/constant-values/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Constantvalues']
        }),        
    })
})

export const {
    useGetConstantvaluesQuery,
    useAddConstantvalueMutation,
    useUpdateConstantvalueMutation,
    useDeleteConstantvalueMutation,
    useGetConstantvaluesByIdQuery,
} = constantvalueSlice

// returns the query result object
export const selectConstantvaluesResult = constantvalueSlice.endpoints.getConstantvalues.select()

// Creates memoized selector
const selectConstantvaluessData = createSelector(
    selectConstantvaluesResult,
    constantvaluesResult => constantvaluesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllConstantvalues,
    selectById: selectConstantvalueById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = constantvaluesAdapter.getSelectors(state => selectConstantvaluessData(state) ?? initialState)
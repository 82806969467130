import React, { useEffect, useState } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Badge from "../../../static/images/avatar/namgyal_school.jpg";
import Controls from "../../dashboard/pages/forms/controls/Controls";
import { useForm } from "../../dashboard/pages/forms/useForm";
import BlobToFile from "../../dashboard/pages/forms/utils/blobToFile";
import { useAddMemberMutation } from "../../../services/memberSlice";
import { ErrorSharp } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import TextButton from "../component/buttons/textButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const Member = () => {
  return (
    <>
      <Header />
      <MemberPage />
      <Footer />
    </>
  );
};

const MemberPage = () => {
  const countryPrice = {
    "": "0",
    Nepal: "NRP 500",
    India: "INR 500",
    USA: "USD 25",
    Canada: "CAD 25",
    UK: "British £ 25",
    France: "€ 25",
    Belgium: "€ 25",
    Switzerland: "€ 25",
    Australia: "Australian $ 25",
    Others: "USD 25",
  };
  const countryItems = [
    { id: "Nepal", title: "Nepal" },
    { id: "India", title: "India" },
    { id: "USA", title: "USA" },
    { id: "Canada", title: "Canada" },
    { id: "UK", title: "UK" },
    { id: "France", title: "France" },
    { id: "Belgium", title: "Belgium" },
    { id: "Switzerland", title: "Switzerland" },
    { id: "Australia", title: "Australia" },
    { id: "Others", title: "Others" },
  ];
  const initialFValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    mailing_address: "",
    cell_phone: "",
    city: "",
    state: "",
    email: "",
    class_of_batch: "",
    membership_fee: 0.0,
    member_signature: [],
    mode_of_payment: "",
    cheque_information: "",
    online_payment: "",
    country: "",
  };
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name ? "" : "First Name is required.";
    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name
        ? ""
        : "Last Name field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Your Email is not valid.";
    if ("mailing_address" in fieldValues)
      temp.mailing_address = fieldValues.mailing_address
        ? ""
        : "Mailing Address field is required.";
    if ("cell_phone" in fieldValues)
      temp.cell_phone = fieldValues.cell_phone
        ? ""
        : "Contact Number field is required.";
    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "City field is required.";
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "State field is required.";
    if ("class_of_batch" in fieldValues)
      temp.class_of_batch = fieldValues.class_of_batch
        ? ""
        : "Class of (Batch) field is required.";
    if ("member_fee" in fieldValues)
      temp.membership_fee = fieldValues.membership_fee
        ? ""
        : "Class of (Batch) field is required.";
    if ("cheque_information" in fieldValues)
      temp.cheque_information = fieldValues.cheque_information
        ? ""
        : "Cheque Information field is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const { values, errors, setErrors, handleImageUpload, handleInputChange } =
    useForm(initialFValues, true, validate, false, false);

  const [File, setFile] = useState();

  useEffect(() => {
    handleImageUpload("member_signature", File);
  }, [File]);

  const [addMember] = useAddMemberMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    var file = BlobToFile(values.member_signature, "signature");

    if (file.length !== 0) {
      formData.append("member_signature", file, "signature.jpg");
    }
    formData.append("first_name", values.first_name);
    formData.append("middle_name", values.middle_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("mailing_address", values.mailing_address);
    formData.append("cell_phone", values.cell_phone);
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("class_of_batch", values.class_of_batch);
    formData.append("membership_fee", countryPrice[values.country]);
    formData.append("cheque_information", values.cheque_information);
    formData.append("online_payment", values.online_payment);
    formData.append("mode_of_payment", values.mode_of_payment);
    formData.append("country", values.country);

    addMember(formData)
      .then((res) => {
        setSuccesMessege("Member Request Has Been Sent.");
        setTimeout(() => {
          setSuccesMessege(null);
        }, [2000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur While Registering Member.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [2000]);
      });
  };

  return (
    <section className="volunteer-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <h2 className="font-color-green mb-4">Member</h2>

            <form
              className="custom-form volunteer-form mb-5 mb-lg-0"
              action="#"
              method="post"
              role="form"
            >
              <h3 className="mb-4">Become a Member today</h3>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <Controls.Input
                    name="first_name"
                    label="First Name"
                    value={values.first_name}
                    onChange={handleInputChange}
                    error={errors.first_name}
                  />
                </div>

                <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                  <Controls.Input
                    name="middle_name"
                    label="Middle Name"
                    value={values.middle_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Input
                    name="last_name"
                    label="Last Name"
                    value={values.last_name}
                    onChange={handleInputChange}
                    error={errors.last_name}
                  />
                </div>

                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Input
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                  />
                </div>
                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Input
                    name="mailing_address"
                    label="Mailing Address"
                    value={values.mailing_address}
                    onChange={handleInputChange}
                    error={errors.mailing_address}
                  />
                </div>
                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Input
                    name="cell_phone"
                    label="Contact Number"
                    value={values.cell_phone}
                    onChange={handleInputChange}
                    error={errors.cell_phone}
                  />
                </div>
                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Select
                    name="country"
                    label="Country"
                    value={values.country}
                    onChange={handleInputChange}
                    options={countryItems}
                  />
                </div>

                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Input
                    name="city"
                    label="City"
                    value={values.city}
                    onChange={handleInputChange}
                    error={errors.city}
                  />
                </div>

                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Input
                    name="state"
                    label="State"
                    value={values.state}
                    onChange={handleInputChange}
                    error={errors.state}
                  />
                </div>
                <div className="col-lg-6 col-12 mt-4">
                  <Controls.Input
                    name="class_of_batch"
                    label="Class of (Batch)"
                    value={values.class_of_batch}
                    onChange={handleInputChange}
                    error={errors.class_of_batch}
                  />
                </div>
                <div className="col-lg-6 col-12 mt-4 text-start">
                  <label style={{fontSize:14,fontWeight:'700'}}>Membership Fee:</label>
                  <p style={{fontSize:17}}>{countryPrice[values.country]}</p>
                </div>
                <div className="col-lg-12 col-12 mt-2">
                  <Controls.RadioGroup
                    name="mode_of_payment"
                    label="Mode of Payment"
                    value={values.mode_of_payment.toString()}
                    onChange={handleInputChange}
                    items={[
                      { id: "cash", title: "Cash" },
                      { id: "cheque", title: "Cheque" },
                      { id: "online", title: "Online" },
                    ]}
                  />
                </div>

                {values.mode_of_payment === "cheque" ? (
                  <div className="col-lg-6 col-12 mt-4">
                    <Controls.Input
                      name="cheque_information"
                      label="Cheque Information"
                      value={values.cheque_information}
                      onChange={handleInputChange}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {values.mode_of_payment === "online" ? (
                  <div className="col-lg-6 col-12 mt-4">
                    <Controls.Input
                      name="online_payment"
                      label="Online Payment"
                      value={values.online_payment}
                      onChange={handleInputChange}
                    />
                  </div>
                ) : (
                  <></>
                )}

                <div className="col-lg-6 col-12 mt-4 text-start">
                  <label style={{fontSize:14,fontWeight:'700'}}>Upload Signature</label>
                  <Controls.Image setFile={setFile} aspectRatio={368 / 246} />
                </div>
              </div>
              <button
                type="submit"
                className="form-control mt-4"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </form>
          </div>

          <div className="col-lg-6 col-12">
            <img src={Badge} className="volunteer-image img-fluid" alt="" />

            <div className="custom-block-body text-center">
              <h4 className="font-color-green mt-lg-3 mb-lg-3">
                About Members
              </h4>

              <p className="font-color-green">
                Joining your alumni association offers you a sense of
                responsibility and belongings. It provides networking
                opportunities, but above all, Namgyal Gokarna Alumni Association
                keeps you connected with your alma mater and our alumni friends
                from different ends. Whether you graduated decades ago or you're
                still in college, NGAA helps you maintain a link with your
                school and alumni friends.
              </p>
              <h4 className="font-color-green">Membership fee (per year):</h4>
              <p className="font-color-green">Nepal: NRP 500</p>
              <p className="font-color-green">India: INR 500</p>
              <p className="font-color-green">USA: USD $ 25</p>
              <p className="font-color-green">UK: British £ 25</p>
              <p className="font-color-green">Canada: CAD 25</p>
              <p className="font-color-green">France: € 25</p>
              <p className="font-color-green">Belgium: € 25</p>
              <p className="font-color-green">Switzerland: € 25</p>
              <p className="font-color-green">Australia: AUD 25</p>
              <p className="font-color-green">Others: USD 25</p>
            </div>
          </div>
        </div>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </div>
    </section>
  );
};

export default Member;

import { Grid } from "@material-ui/core";
import { Box } from "@mui/system";
import React from "react";
import Err from "../../../static/images/error.png";

const Error = () => {
  return (
    <Box>
      <Grid item xs={12} md={6} style={{ margin: "0 auto" }}>
        <img src={Err} style={{ width: "100%" }} />
      </Grid>
    </Box>
  );
};

export default Error;

import React from "react";
import { Grid } from "@mui/material";
import {Box} from "@mui/material";

const Single_Gallery = ({image}) => {


return(
<Grid item xs={12} md={4}>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[0]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "300px",borderRadius: '10px' }}
      />
    </Box>
  </Grid>
)

}

export default Single_Gallery;
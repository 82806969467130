import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const membersAdapter = createEntityAdapter()

const initialState = membersAdapter.getInitialState()

export const memberSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMembers: builder.query({
            query: () => '/members/membership-form/',
            transformResponse: responseData => {
                return membersAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Members', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Members', id }))
            ]
        }),
        getMembersById: builder.query({
            query: (id)=>({

                url:`/members/membership-form/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Members']
        }),
        addMember: builder.mutation({
            query: (member) => {
            return{
                url: '/members/membership-form/',
                method: 'POST',
                body: member,
            }
        },
            invalidatesTags: ['Members']
        }),
        updateMember: builder.mutation({
           
            query: (updateMember) => {
                return{
                url: `/members/membership-form/${updateMember.get('id')}/`,
                method: 'PATCH',
                body: updateMember
                }
            },
            invalidatesTags: ['Members']
        }),
        deleteMember: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/members/membership-form/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Members']
        }),
    })
})

export const {
    useGetMembersQuery,
    useAddMemberMutation,
    useUpdateMemberMutation,
    useDeleteMemberMutation,
    useGetMembersByIdQuery
} = memberSlice

// returns the query result object
export const selectMembersResult = memberSlice.endpoints.getMembers.select()

// Creates memoized selector
const selectMemberssData = createSelector(
    selectMembersResult,
    membersResult => membersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllMembers,
    selectById: selectMemberById,
    selectIds: selectMemberIds
    // Pass in a selector that returns the posts slice of state
} = membersAdapter.getSelectors(state => selectMemberssData(state) ?? initialState)
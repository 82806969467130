import React, { useState } from "react";
import Header from "../component/header";
import Footer from "../component/footer";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../dashboard/pages/forms/controls/Button";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosInstance from "../../dashboard/reusable/axios";
import { Grid } from "@material-ui/core";

const Login = () => {
  return (
    <>
      <Header />
      <LoginPage />
      <Footer />
    </>
  );
};

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const onEmail = (e) => setEmail(e.target.value);
  const onPassword = (e) => setPassword(e.target.value);

  const clickBtn = (e) => {
    e.preventDefault();
    const loginData = { email, password };
    axiosInstance
      .post("/user/login/", loginData)
      .then((response) => {
        var user_email = response.data.data.email;
        // var a = response.data.data.tokens;
        // 
        // var count = 0;
        // var refresh = "";
        // var access = "";
        // for (var i = 0; i < a.length; i++) {
        //   if (a[i] === "'") {
        //     i++;
        //     count++;
        //   }
        //   if (count === 3 && count < 4) {
        //     refresh = refresh + a[i];
        //   }
        //   if (count === 7 && count < 8) {
        //     access = access + a[i];
        //   }
        // }

        Cookies.set("access", response.data.data.tokens.access);
        Cookies.set("refresh", response.data.data.tokens.refresh);
        Cookies.set("user_email", user_email);
        

        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + Cookies.get("access");
        setSuccesMessege("Successfully Logged In.");
        setTimeout(() => {
          if (response.data.data.is_admin == true) {
            Cookies.set("is_admin", response.data.data.is_admin);
            history("/dashboard/home");
            setTimeout(()=>{
                window.location.reload(true);
            },[2000])
          } else {
            history("/");
          }
        }, [3000]);
      })

      .catch((err) => {
        setErrorMessege("Some Error Occur While Login In.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="signinmargin res-padding" style={{ textAlign: "start" }}>
      <Grid
        item
        sm={12}
        md={6}
        xs={12}
        style={{ margin: "0 auto" }}
        className="signinauto"
      >
        <Grid container>
          <Grid item md={6} xs={12} className="signinleft ">
            <Box >
              <Grid
                item
                xs={3}
                style={{ margin: "0 auto" }}
                className="signinleftmargin "
              >
                <Box>
                  <Typography className="signintext">Login</Typography>
                </Box>
                {/* <Box className='logindata'>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <span className='facebookicon' ><FontAwesomeIcon icon={faFacebookF} /></span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span className='googleicon'onClick={()=>signIn()}><FontAwesomeIcon icon={faGoogle} /></span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            
                                        </Grid>
                                    </Grid>
                                </Box> */}
                <Box>
                  <Typography className="signintext1">
                    or use your account
                  </Typography>
                </Box>
              </Grid>
            </Box>
            <Box className="newforms">
              <Box>
                <lable className="emailtext">Email*</lable>
                <input
                  type="email"
                  onChange={onEmail}
                  className="titleform2"
                  placeholder="Enter Your Email"
                />
              </Box>
              <Box className="passwordbtn">
                <lable className="emailtext">Password*</lable>
                <input
                  type="password"
                  onChange={onPassword}
                  className="titleform2"
                  placeholder="Enter Your Password"
                />
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Box className="remembermargin">
                    <input type="checkbox" />
                    <lable className="fs_13">Remember Me</lable>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="forgotpassword1">
                    <Link
                      className="forgotpassword"
                      to="/forgetpassword/emailverification"
                    >
                      Forgot Password?
                    </Link>
                  </Box>
                </Grid>
              </Grid>
              <button
                onClick={clickBtn}
                className="signinbutton"
                style={{ border: "none" }}
              >
                Login
              </button>
            </Box>
          </Grid>
          <Grid item xs={6} className="leftcolor res-display">
            <Box className="signinnayamargin">
              <Typography className="accounttext">
                Don't Have an Account?
              </Typography>
              <Link to="/signup">
                <button className="signupnewbtn">Sign Up</button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Login;

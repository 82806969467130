import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetConstantvaluesQuery,
  useUpdateConstantvalueMutation,
  useDeleteConstantvalueMutation,
  useAddConstantvalueMutation,
} from "../../../../services/constantvalueSlice";
import ConstantvalueAddButton from "./constantvalueAddButton";
import { Box } from "@mui/system";

const DashboardConstantvalues = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardConstantvaluesPages />
      </Grid>
    </Grid>
  );
};

const DashboardConstantvaluesPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetConstantvaluesQuery("getConstantvalues");

  const [deleteConstantvalue] = useDeleteConstantvalueMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    
    deleteConstantvalue({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Membership Fee", "Official Signature"];
    tableData = [];
    data.forEach((key) => {
      tableData.push({
        "Membership Fee": key.membership_fee,
        "Official Signature": key.official_signature,
        id: key.id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Constant Value List
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/constantvalues" style={{textDecoration:'none'}}>
              <ConstantvalueAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"constantvalues"}
            deleteConfirm={deleteConfirm}
            activeSign={['Active']}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardConstantvalues;

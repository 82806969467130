import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const donationInformationsAdapter = createEntityAdapter()

const initialState = donationInformationsAdapter.getInitialState()

export const donationInformationSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDonationInformations: builder.query({
            query: () => '/basic/donate-information/',
            transformResponse: responseData => {
                return donationInformationsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'DonationInformations', id: "LIST" },
                ...result.ids.map(id => ({ type: 'DonationInformations', id }))
            ]
        }),
        getDonationInformationsActive: builder.query({
            query: ()=>({

                url:`/basic/donate-information/?is_active=True/`,
                method: 'GET',
            }),
            providesTags: ['DonationInformations']
        }),
        getDonationInformationsById: builder.query({
            query: (id)=>({

                url:`/basic/donate-information/${id}/`,
                method: 'GET',
            }),
            providesTags: ['DonationInformations']
        }),
        addDonationInformation: builder.mutation({
            query: (donationInformation) => {
            return{
                url: '/basic/donate-information/',
                method: 'POST',
                body: donationInformation,
            }
        },
            invalidatesTags: ['DonationInformations']
        }),
        updateDonationInformation: builder.mutation({
           
            query: (updateDonationInformation) => {
                return{
                url: `/basic/donate-information/${updateDonationInformation.get('id')}/`,
                method: 'PATCH',
                body: updateDonationInformation
                }
            },
            invalidatesTags: ['DonationInformations']
        }),
        deleteDonationInformation: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/donate-information/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['DonationInformations']
        }),
    })
})

export const {
    useGetDonationInformationsQuery,
    useAddDonationInformationMutation,
    useUpdateDonationInformationMutation,
    useDeleteDonationInformationMutation,
    useGetDonationInformationsByIdQuery,
    useGetDonationInformationsActiveQuery,
} = donationInformationSlice

// returns the query result object
export const selectDonationInformationsResult = donationInformationSlice.endpoints.getDonationInformations.select()

// Creates memoized selector
const selectDonationInformationssData = createSelector(
    selectDonationInformationsResult,
    donationInformationsResult => donationInformationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllDonationInformations,
    selectById: selectDonationInformationById,
    selectIds: selectDonationInformationIds
    // Pass in a selector that returns the posts slice of state
} = donationInformationsAdapter.getSelectors(state => selectDonationInformationssData(state) ?? initialState)
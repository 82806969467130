import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import {  Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../services/constants";
import Footer from "../component/footer";
import Header from "../component/header";

const Signup = () => {
  return (
    <>
      <Header />
      <SignupPage />
      <Footer />
    </>
  );
};

const SignupPage = () => {
  const history = useNavigate();

  //user registeration using state management
  const [first_name, setFirstname] = useState([]);
  const [last_name, setLastName] = useState([]);
  const [email, setEmail] = useState([]);
  const [contactno, setContactnumber] = useState([]);
  const [password, setPassword] = useState([]);
  const [Cpassword, setCpassword] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const onFirstName = (e) => setFirstname(e.target.value);
  const onLastName = (e) => setLastName(e.target.value);
  const onEmail = (e) => setEmail(e.target.value);
  const onContact = (e) => setContactnumber(e.target.value);
  const onPassword = (e) => setPassword(e.target.value);
  const onCpassword = (e) => setCpassword(e.target.value);

  const Register = (e) => {
    e.preventDefault();
    if (password == Cpassword) {
      var formData = new FormData();
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("contactno", contactno);
      formData.append("password", password);

      const postingData = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };
      axios
        .post(`${BASE_URL}/user/register/`, formData, postingData)
        .then((response) => {
          setSuccesMessege(
            "User Has Been Register Successfully. Please Verify Email."
          );
          setTimeout(() => {
            history("/login");
          }, [3000]);
        })
        .catch((err) => {
          
          setErrorMessege(
            "Some Error Occur while Registering the User."
          );
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        });
    } else {
      setErrorMessege("Las contraseñas no coinciden.");
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };
  return (
    <Box className="signinmargin res-padding" style={{textAlign:"start"}}>
      <Grid item xs={12} md={8} className="signinauto">
        <Grid container>
          <Grid item xs={6} className="leftcolor res-display">
            <Box className="signinnayamargin1">
              <Typography className="accounttext">
                Already Have an Account?
              </Typography>
              <Link to="/login">
                  <button className="signupnewbtn">Login</button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className="signinleft">
            <Box>
              <Typography className="signintext">Sign Up</Typography>
            </Box>
            <Box className="newforms">
              <Box>
                <lable className="emailtext">First Name*</lable>
                <input
                  type="text"
                  onChange={onFirstName}
                  className="titleform2"
                  placeholder="Enter Your First Name"
                  required
                />
              </Box>
              <Box className="passwordbtn">
                <lable className="emailtext">Last Name*</lable>
                <input
                  type="text"
                  onChange={onLastName}
                  className="titleform2"
                  placeholder="Enter Your Last Name"
                  required
                />
              </Box>
              <Box className="passwordbtn">
                <lable className="emailtext">Email*</lable>
                <input
                  type="email"
                  onChange={onEmail}
                  className="titleform2"
                  placeholder="Enter Your Email"
                  required
                />
              </Box>
              <Box className="passwordbtn">
                <lable className="emailtext">Contact Number*</lable>
                <input
                  type="number"
                  onChange={onContact}
                  className="titleform2"
                  placeholder="Enter Your Contact"
                  required
                />
              </Box>
              <Box className="passwordbtn">
                <lable className="emailtext">Password*</lable>
                <input
                  type="password"
                  onChange={onPassword}
                  className="titleform2"
                  placeholder="Enter Your Password"
                  required
                />
              </Box>
              <Box className="passwordbtn">
                <lable className="emailtext">Confirm Password*</lable>
                <input
                  type="password"
                  onChange={onCpassword}
                  className="titleform2"
                  placeholder="Enter Your Password Again"
                  required
                />
              </Box>
              <button className="signinbutton" onClick={Register} style={{border:"none"}}>
                Sign Up
              </button>
            </Box>
            {SuccessMessege === null ? null : (
              <Box className="successmessege">
                <Typography className="messegefonts">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="messegeicon"
                  />
                  {SuccessMessege}
                </Typography>
              </Box>
            )}
            {ErrorMessege === null ? null : (
              <Box className="errormessege">
                <Typography className="messegefonts">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="messegeicon"
                  />
                  {ErrorMessege}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;

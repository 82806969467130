import React from "react";
import Slide1 from "../../../../static/images/slide/volunteer-helping-with-donation-box.jpg";
import Slide2 from "../../../../static/images/slide/volunteer-selecting-organizing-clothes-donations-charity.jpg";
import Slide3 from "../../../../static/images/slide/medium-shot-people-collecting-donations.jpg";
import { useGetHomePagesQuery } from "../../../../services/homePageSlice";

const ImageSlider = () => {
  const data = useGetHomePagesQuery("getHomePages");

  return (
    <section className="hero-section hero-section-full-height">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-12 p-0">
            <div
              id="hero-slide"
              className="carousel carousel-fade slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {data !== undefined ? (
                  <>
                    {data.status === "fulfilled" ? (
                      <>
                        {data.data.ids.map((key) => (
                          <div
                            className={
                              data.data.ids[0]
                                ? "carousel-item active"
                                : "carousel-item"
                            }
                            key={key}
                          >
                            <img
                              src={data.data.entities[key].image}
                              className="carousel-image img-fluid"
                              alt="..."
                            />

                            <div className="carousel-caption d-flex flex-column justify-content-end">
                              <h1>{data.data.entities[key].title}</h1>

                              <p style={{ color: "white" }}>
                                {data.data.entities[key].subtitle}
                              </p>
                            </div>
                          </div>
                        ))}{" "}
                      </>
                    ) : null}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#hero-slide"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>

              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#hero-slide"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageSlider;

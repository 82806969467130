import React from "react";
import Single_Gallery from "./singleGallery";
import Double_Gallery from "./doubleGallery";
import Three_Gallery from "./threeGallery";
import Four_Gallery from "./fourGallery";
import Five_Gallery from "./fiveGallery";
import Six_Gallery from "./sixGallery";
import Seven_Gallery from "./sevenGallery";
import Eight_Gallery from "./eightGallery";

const Reusable_Gallery =({dataArray})=> {
    

    const nullGallery = () =>{
        return(<></>)
    }

    const singleGallery = (dataArray)=>{
        return (
            <><Single_Gallery image={dataArray}/></>
        )
    }
    const doubleGallery = (dataArray)=>{
        return (
            <><Double_Gallery image={dataArray}/></>
        )
    }
    const threeGallery = (dataArray)=>{
        return (
            <><Three_Gallery image={dataArray}/></>
        )
    }
    const fourGallery = (dataArray)=>{
        return (
            <><Four_Gallery image={dataArray}/></>
        )
    }
    const fiveGallery = (dataArray)=>{
        return (
            <><Five_Gallery image={dataArray}/></>
        )
    }
    const sixGallery = (dataArray)=>{
        return (
            <><Six_Gallery image={dataArray}/></>
        )
    }
    const sevenGallery = (dataArray)=>{
        return (
            <><Seven_Gallery image={dataArray}/></>
        )
    }
    const eightGallery = (dataArray)=>{
        return (
            <><Eight_Gallery image={dataArray}/></>
        )
    }
    // if(dataArray.length>8)
    // {
    //   dataArray = dataArray.slice(0,8);
    // }
    var content = nullGallery()
  
    if (dataArray.length == 0){
      content = nullGallery()
    }
    else if(dataArray.length == 1)
    {
      content = singleGallery(dataArray)
    }
    else if(dataArray.length == 2)
    {
      content = doubleGallery(dataArray)
    }
    else if(dataArray.length == 3)
    {
      content = threeGallery(dataArray)
    }
    else if(dataArray.length == 4)
    {
      content = fourGallery(dataArray)
    }
    else if(dataArray.length == 5)
    {
      content = fiveGallery(dataArray)
    }
    else if(dataArray.length == 6)
    {
      content = sixGallery(dataArray)
    }
    else if(dataArray.length == 7)
    {
      content = sevenGallery(dataArray)
    }
    else if(dataArray.length == 8)
    {
      content = eightGallery(dataArray)
    }
  
    return(
        <>
        {content}</>

    )
}

export default Reusable_Gallery;


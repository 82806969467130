import {
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Header from "../component/header";
import Sidebar from "../component/sidebar";
import { TiCalendarOutline } from "react-icons/ti";
import { MdOutlineContactMail } from "react-icons/md";
import { GiNewspaper } from "react-icons/gi";
import { RiProductHuntLine } from "react-icons/ri";
import {AiOutlineCheck,AiOutlineClose,AiOutlineEye} from "react-icons/ai"
import DashboardMembers from "./membersDashboard/memberDashboardHome";
import { useGetCountQuery } from "../../../services/blogSlice";

const DashboardHome = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardPage />
      </Grid>
    </Grid>
  );
};

const DashboardPage = () => {
 const {data} = useGetCountQuery('getCount');
  return (
    <Box style={{ padding: "1rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box className="dashboard-card-design" component={Paper}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box>
                  <Typography className="dashboard-card-design-text">
                   Total's Donation
                  </Typography>
                </Box>
                <Box>
                  {data?
                  <Typography variant="h1" className="appointment-list-text">
                    {data.appointment}
                  </Typography>:<></>}
                </Box>
                <Box>
                  <Typography className="daily-appointment-percentage">
                    <span
                      style={{ color: "rgb(45, 206, 137)", fontWeight: "600" }}
                    >
                      +50%
                    </span>{" "}
                    since yesterday
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <TiCalendarOutline className="dashboard-appointment-list" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className="dashboard-card-design" component={Paper}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box>
                  <Typography className="dashboard-card-design-text">
                    Total Services
                  </Typography>
                </Box>
                <Box>
                  {data?
                  <Typography variant="h1" className="appointment-list-text">
                    {data.service}
                  </Typography>:<Typography variant="h1" className="appointment-list-text">
                  
                  </Typography>}
                </Box>
                <Box>
                  <Typography className="daily-appointment-percentage">
                    <span
                      style={{ color: "rgb(45, 206, 137)", fontWeight: "600" }}
                    >
                      +50%
                    </span>{" "}
                    since yesterday
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <MdOutlineContactMail className="dashboard-appointment-list" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className="dashboard-card-design" component={Paper}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box>
                  <Typography className="dashboard-card-design-text">
                    Total Product
                  </Typography>
                </Box>
                <Box>
                  {data?
                  <Typography variant="h1" className="appointment-list-text">
                    {data.product}
                  </Typography>:<Typography variant="h1" className="appointment-list-text">
                    
                  </Typography>}
                </Box>
                <Box>
                  <Typography className="daily-appointment-percentage">
                    <span
                      style={{ color: "rgb(45, 206, 137)", fontWeight: "600" }}
                    >
                      +50%
                    </span>{" "}
                    since yesterday
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <GiNewspaper className="dashboard-appointment-list" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className="dashboard-card-design" component={Paper}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box>
                  <Typography className="dashboard-card-design-text">
                    Total Blogs
                  </Typography>
                </Box>
                <Box>
                  {
                    data?<Typography variant="h1" className="appointment-list-text">
                    {data.blog}
                  </Typography>:
                  
                  <Typography variant="h1" className="appointment-list-text">
                  
                  </Typography>}
                </Box>
                <Box>
                  <Typography className="daily-appointment-percentage">
                    <span
                      style={{ color: "rgb(45, 206, 137)", fontWeight: "600" }}
                    >
                      +50%
                    </span>{" "}
                    since yesterday
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <RiProductHuntLine className="dashboard-appointment-list" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box style={{ marginTop: "1.5rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DashboardMembers/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default DashboardHome;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetMembersByIdQuery } from "../../../../services/memberSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateMemberMutation } from "../../../../services/memberSlice";
import urlToFile from "../forms/utils/urlToFile";
import { useGetServicesQuery } from "../../../../services/serviceSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetConstantvaluesQuery } from "../../../../services/constantvalueSlice";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineCardMembership } from "react-icons/md";
import { create } from "@mui/material/styles/createTransitions";

const MemberEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditMemberPages />
      </Grid>
    </Grid>
  );
};

const EditMemberPages = () => {

  const countryPrice = {
    '': '0',
    'Nepal': 'NRP 500',
    'India': 'INR 500',
    'USA': 'USD 25',
    'Canada': 'CAD 25',
    'UK': 'British £ 25',
    'France': '€ 25',
    'Belgium': '€ 25',
    'Switzerland': '€ 25',
    'Australia': 'Australian $ 25',
    'Others': 'USD 25'
  }
  const countryItems = [
    { id: 'Nepal', title: 'Nepal' },
    { id: 'India', title: 'India' },
    { id: 'USA', title: 'USA' },
    { id: 'Canada', title: 'Canada'},
    { id: 'UK', title: 'UK' },
    { id: 'France', title: 'France'},
    { id: 'Belgium', title: 'Belgium'},
    { id: 'Switzerland', title: 'Switzerland'},
    { id: 'Australia', title: 'Australia'},
    { id: 'Others', title: 'Others'},
]
  const { id } = useParams();
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const [updateConstant, setUpdateConstant] = useState({});
  const Members = useGetMembersByIdQuery(id);

  const constantValues = useGetConstantvaluesQuery("getConstantvalues");
  
  // useEffect(()=>{
  //   if(constantValues.status == "fulfilled")
  //   {
  //     setUpdateConstant(constantValues.data[0])
  //   }
  // },[constantValues])

  

  const [memberSignature, setMemberSignature] = useState([]);
  const [officialSignature, setOfficialSignature] = useState([]);
  const [updateMember, setUpdateMember] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    first_name : "",
    middle_name: "",
    last_name: "",
    mailing_address: "",
    cell_phone: "",
    country: '',
    city: "",
    state: "",
    email: "",
    class_of_batch: "",
    membership_fee: 0.0,
    member_signature: [],
    mode_of_payment: "",
    cheque_information: '',
    online_payment: '',
    official_signature: [],
    is_active: false,
  });

  useEffect(()=>{
    if(Members.data)
    {
      // urlToFile(updateConstant.official_signature, setOfficialSignature)
      urlToFile(Members.data.member_signature, setMemberSignature)
    }
  },[Members.data])

  
  

  useEffect(() => {
    if (Members.data) {
      var confirmed_date = new Date(Members.data.confirmed_date)
      setInitialFValues({
        first_name : Members.data.first_name,
        middle_name: Members.data.middle_name,
        last_name: Members.data.last_name,
        mailing_address: Members.data.mailing_address,
        cell_phone: Members.data.cell_phone,
        country: Members.data.country,
        city: Members.data.city,
        state: Members.data.state,
        email: Members.data.email,
        class_of_batch: Members.data.class_of_batch,
        membership_fee: countryPrice[Members.data.country],
        member_signature: memberSignature,
        mode_of_payment: Members.data.mode_of_payment,
        cheque_information: Members.data.cheque_information,
        online_payment: Members.data.online_payment,
        // official_signature: officialSignature,
        is_active: Members.data.is_active.toString(),
      });
    }
  }, [Members.data, memberSignature, officialSignature]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );
  

  const [editMember] = useUpdateMemberMutation();
  const history = useNavigate();
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(values.first_name)
      formData.append("first_name", values.first_name);
    if(values.middle_name)
      formData.append("middle_name", values.middle_name);
    if(values.last_name)
      formData.append("last_name", values.last_name);
    if(values.mailing_address)
      formData.append("mailing_address", values.mailing_address);
    if(values.cell_phone)
      formData.append("cell_phone", values.cell_phone);
    if(values.city)
      formData.append("city", values.city);
    if(values.state)
      formData.append("state", values.state);
    if(values.email)
      formData.append("email", values.email);
    if(values.class_of_batch)
       formData.append("class_of_batch", values.class_of_batch);
    if(values.membership_fee)
      formData.append("membership_fee", countryPrice[values.country]);
    if(values.country)
      formData.append('country', values.country);
    var file1 = BlobToFile(values.member_signature, "member_signature");
    
    if (values.member_signature.length !== 0) {
        formData.append("member_signature", file1, "member_signature.jpg");
      }
    // var file2 = BlobToFile(values.official_signature, "official_signature");
    
    // formData.append("official_signature", file2, "official_signature.jpg");
    
    if(values.mode_of_payment) 
      formData.append('mode_of_payment', values.mode_of_payment);
    if(values.cheque_information)
      formData.append('cheque_information', values.cheque_information);
    if(values.online_payment)
      formData.append('online_payment', values.online_payment);
    if(values.is_active)
      formData.append('is_active', values.is_active);
    // if(typeof(values.confirmed_date) === "object")
    //   formData.append("confirmed_date", values.confirmed_date.toISOString());
    // else
    //   formData.append("confirmed_date", values.confirmed_date);
    formData.append("id", id);
    editMember(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Member.");
        setTimeout(() => {
          history("/dashboard/home");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Member Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6} style={{ paddingLeft: "1.5rem" }}>
              <Grid container spacing={3}>
                <Controls.Input
                name="first_name"
                label="First Name"
                value={values.first_name}
                onChange={handleInputChange}
                />
                <Controls.Input
                name="middle_name"
                label="Middle Name"
                value={values.middle_name}
                onChange={handleInputChange}
                />
                <Controls.Input
                name="last_name"
                label="Last Name"
                value={values.last_name}
                onChange={handleInputChange}
                />
                <Controls.Input
                name="mailing_address"
                label="Mailing Address"
                value={values.mailing_address}
                onChange={handleInputChange}
                />
                <Controls.Input
                name="cell_phone"
                label="Cell Phone"
                value={values.cell_phone}
                onChange={handleInputChange}
                />
                <Controls.Select
                name='country'
                label="Country"
                value={values.country}
                onChange={handleInputChange}
                options={countryItems}
                />
                <Controls.Input
                name="city"
                label="City"
                value={values.city}
                onChange={handleInputChange}
                />
                <Controls.Input
                name="state"
                label="state"
                value={values.state}
                onChange={handleInputChange}
                />
                <Controls.Input
                name="email"
                label="Email"
                value={values.email}
                onChange={handleInputChange}
                />
                 <Controls.Input
                name="class_of_batch"
                label="Class Of Batch"
                value={values.class_of_batch}
                onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <label>Membership Fee</label>
              <p>{countryPrice[values.country]}</p>

            <Controls.RadioGroup
                name="mode_of_payment"
                label="Mode of Payment"
                value={values.mode_of_payment.toString()}
                onChange={handleInputChange}
                items={[
                  { id: "cash", title: "Cash" },
                  { id: "cheque", title: "Cheque" },
                  { id: "online", title: "Online" },
                ]}
              />
            {values.mode_of_payment === "cheque"?
            <Controls.Input
                name="cheque_information"
                label="Cheque Information"
                value={values.cheque_information}
                onChange={handleInputChange}
                />:<></>}
            {values.mode_of_payment === "online"?
                <Controls.Input
                name="online_payment"
                label="Online Payment"
                value={values.online_payment}
                onChange={handleInputChange}
                />:<></>}

              <Controls.RadioGroup
                name="is_active"
                label="Is Confirmed"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
              <label style={{fontSize:'16px',fontWeight:'600'}}>Member Signature</label>
              {Members.data?<>
              {Members.data.member_signature?
              <img style={{width:"100%",marginTop:'10px'}} src={Members.data.member_signature}></img>:<></>}</>:<></>}
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                onClick={handleSubmit}
                backgroundColor="#1b284b"
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MemberEditPage;

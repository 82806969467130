import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {useState} from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Charity from "../../../static/images/portrait-volunteer-who-organized-donations-charity.jpg";
import { useGetImageGallerysPaginationQuery } from "../../../services/imageGallerySlice";
import Reusable_Gallery from "../../dashboard/reusable/galleryComponents/reusableGallery";
import { Grid } from "@material-ui/core";
import PaginationComponent from "../../dashboard/component/PaginationComponent";
import usePagination from "../../dashboard/component/pagination";
import {Pagination, Stack} from "@mui/material";

const Gallery = () => {
  return (
    <Box>
      <Header />
      <Gallery_page />
      <Footer />
    </Box>
  );
};

const Gallery_page = () => {
  const [pageId, setPageId] = useState(1);
  const { data, isLoading, isSuccess, isError, error } =
  useGetImageGallerysPaginationQuery(pageId);



const dataArray = [];
var count = 8;
if (isSuccess) {
  data.results.forEach((key) => {
    dataArray.push(key.image);
  });
  count = data.count;
}

const [page, setPage] = useState(1);
const PER_PAGE = 8;


const _DATA = usePagination(dataArray, count, PER_PAGE);

const handleChange = (e, p) => {
  setPage(p);
  _DATA.jump(p);
  setPageId(p);
};


  return (
    <Box style={{ marginBottom: "2.5rem" }}>
      <Container>
        <Box className="custom-text-box">
          <h2>Our Gallery</h2>
          <p>Namgyal Gokarna Alumni Association photos</p>
        </Box>
        <Grid container spacing={3}>
          <Reusable_Gallery dataArray={dataArray}/>
        </Grid>
        <Box style={{ marginTop: "20px", textAlign: "center" }}>
            <Stack spacing={2} style={{textAlign:"center"}}>
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            </Stack>
          </Box>
        
        {/* <div className="row imageGallery1" id="gallery">
          {data !== undefined ? (
            <>
              {data.status === "fulfilled" ? (
                <>
                  {data.data.ids.map((key) => (
                    <div className="col-md-4 gallery_item">
                      <div className="gallery_img">
                        <img
                          src={data.data.entities[key].image}
                          alt="gallery"
                        />
                        <div className="hover">
                          <a
                            className="light"
                            href={data.data.entities[key].image}
                          >
                            <i className="fa fa-expand"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </>
          ) : null}
        </div> */}
      </Container>
    </Box>
  );
};

export default Gallery;

import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddDonationInformationMutation } from "../../../../services/donationInformationSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddDonationInformation = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddDonationInformationPages />
      </Grid>
    </Grid>
  );
};

const AddDonationInformationPages = () => {
  const [File, setFile] = useState([]);
  const initialFValues = {
    bank_information: "",
    esewa_information: "",
    account_name: "",
    account_number: "",
    bank_name: "",
    swift_code: "",
    address: ""
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  const [addDonationInformation] = useAddDonationInformationMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(values.bank_information)
    {
      formData.append("bank_information", values.bank_information);
    }
    if(values.esewa_information)
    {
        formData.append("esewa_information", values.esewa_information);  
    }
    if(values.account_name)
    {
      formData.append("account_name", values.account_name)
    }
    if(values.account_number)
    {
      formData.append("account_number", values.account_number)
    }
    if(values.bank_name)
    {
      formData.append("bank_name", values.bank_name)
    }
    if(values.swift_code)
    {
      formData.append("swift_code", values.swift_code)
    }
    if(values.address)
    {
      formData.append("address", values.address)
    }

    addDonationInformation(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added DonationInformations Data.");
        setTimeout(() => {
          history("/dashboard/donation-information");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">Add DonationInformations</Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="bank_information"
                label="Bank Information"
                value={values.bank_information}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="esewa_information"
                label="esewa Information"
                value={values.esewa_information}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="account_name"
                label="Account Name"
                value={values.account_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="account_number"
                label="Account Number"
                value={values.account_number}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="bank_name"
                label="Bank Name"
                value={values.bank_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="swift_code"
                label="Swift Code"
                value={values.swift_code}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="address"
                label="address"
                value={values.address}
                onChange={handleInputChange}
              />
              </Grid>         
          </Grid>
          <Grid container>
          <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
            </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddDonationInformation;

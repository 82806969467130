import React from "react";
import {
  useGetAboutPagesQuery,
  useGetActiveAboutPagesQuery,
} from "../../../../services/aboutPageSlice";
import { useGetMissionsQuery } from "../../../../services/missionSlice";
import { useGetVisionsQuery } from "../../../../services/visionSlice";
import Volunteer from "../../../../static/images/group-people-volunteering-foodbank-poor-people.jpg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const About = () => {
  const data = useGetActiveAboutPagesQuery("getAboutPages");
  
  const data1 = useGetMissionsQuery("getMissions");
  const data2 = useGetVisionsQuery("getVisions");
  return (
    <section className="section-padding section-bg" id="section_2">
      <div className="container">
        {data !== undefined ? (
          <>
            {data.status === "fulfilled" ? (
              <>
                {data.data.ids.map((key) => (
                  <div className="row" key={key}>
                    <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                      <img
                        src={data.data.entities[key].image}
                        className="custom-text-box-image img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="custom-text-box">
                        <h2 className="mb-2">
                          {data.data.entities[key].title}
                        </h2>

                        <h5 className="mb-3" style={{ color: "black" }}>
                          {data.data.entities[key].subtitle}
                        </h5>

                        <p className="mb-0">
                          {data.data.entities[key].description}
                        </p>
                      </div>

                      <div className="row">
                        {data2 !== undefined ? (
                          <>
                            {data2.status === "fulfilled" ? (
                              <>
                                <div className="col-lg-12 col-md-12 col-12">
                                  <div className="custom-text-box mb-lg-0">
                                    <h5
                                      className="mb-3"
                                      style={{ color: "black" }}
                                    >
                                      Our Vision
                                    </h5>

                                    {data2.data.ids.map((key) => (
                                      <ul className="custom-list mt-2">
                                        <li className="custom-list-item d-flex">
                                          <i className="bi-check custom-text-box-icon me-2"></i>
                                          {data2.data.entities[key].vision_name}
                                        </li>
                                      </ul>
                                    ))}
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                    {data1 !== undefined ? (
                      <>
                        {data1.status === "fulfilled" ? (
                          <>
                            <div className="col-lg-12 col-md-12 col-12 mt-4">
                              <div className="custom-text-box mb-lg-0">
                                <h5 className="mb-3" style={{ color: "black" }}>
                                  Our Mission
                                </h5>
                                {data1.data.ids.map((key) => (
                                  <ul className="custom-list mt-2" key={key}>
                                    <li className="custom-list-item d-flex">
                                      <i className="bi-check custom-text-box-icon me-2"></i>
                                      {data1.data.entities[key].mission_name}
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                    <SkeletonTheme color="grey" highlightColor="#444">
                      <p>
                        <Skeleton height={480} width={"100%"} count={1} />
                      </p>
                    </SkeletonTheme>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="custom-text-box">
                      <h2 className="mb-2">
                        <Skeleton />
                      </h2>

                      <h5 className="mb-3">
                        <Skeleton />
                      </h5>

                      <p className="mb-0">
                        <Skeleton />
                      </p>
                    </div>

                    <div className="row">
                      {data1 !== undefined ? (
                        <>
                          {data1.status === "fulfilled" ? (
                            <>
                              <div className="col-lg-6 col-md-6 col-12">
                                <div className="custom-text-box mb-lg-0">
                                  <h5 className="mb-3">Our Mission</h5>
                                  {data1.data.ids.map((key) => (
                                    <ul className="custom-list mt-2" key={key}>
                                      <li className="custom-list-item d-flex">
                                        <i className="bi-check custom-text-box-icon me-2"></i>
                                        {data1.data.entities[key].mission_name}
                                      </li>
                                    </ul>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-lg-6 col-md-6 col-12">
                              <div className="custom-text-box mb-lg-0">
                                <h5 className="mb-3">Our Mission</h5>
                                <ul className="custom-list mt-2">
                                  <li className="custom-list-item d-flex">
                                    <SkeletonTheme
                                      color="grey"
                                      highlightColor="#444"
                                    >
                                      <p>
                                        <Skeleton
                                          height={20}
                                          width={20}
                                          count={1}
                                        />
                                      </p>
                                    </SkeletonTheme>
                                    <Skeleton className="mb-0" />
                                  </li>
                                </ul>
                                <ul className="custom-list mt-2">
                                  <li className="custom-list-item d-flex">
                                    <SkeletonTheme
                                      color="grey"
                                      highlightColor="#444"
                                    >
                                      <p>
                                        <Skeleton
                                          height={20}
                                          width={20}
                                          count={1}
                                        />
                                      </p>
                                    </SkeletonTheme>
                                    <Skeleton className="mb-0" />
                                  </li>
                                </ul>
                                <ul className="custom-list mt-2">
                                  <li className="custom-list-item d-flex">
                                    <SkeletonTheme
                                      color="grey"
                                      highlightColor="#444"
                                    >
                                      <p>
                                        <Skeleton
                                          height={20}
                                          width={20}
                                          count={1}
                                        />
                                      </p>
                                    </SkeletonTheme>
                                    <p>
                                      <Skeleton className="mb-0" />
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                      {data2 !== undefined ? (
                        <>
                          {data2.status === "fulfilled" ? (
                            <>
                              <div className="col-lg-6 col-md-6 col-12">
                                <div className="custom-text-box mb-lg-0">
                                  <h5 className="mb-3">Our Vision</h5>

                                  {data2.data.ids.map((key) => (
                                    <ul className="custom-list mt-2">
                                      <li className="custom-list-item d-flex">
                                        <i className="bi-check custom-text-box-icon me-2"></i>
                                        {data2.data.entities[key].vision_name}
                                      </li>
                                    </ul>
                                  ))}
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-lg-6 col-md-6 col-12">
                              <div className="custom-text-box mb-lg-0">
                                <h5 className="mb-3">Our Vision</h5>
                                <ul className="custom-list mt-2">
                                  <li className="custom-list-item d-flex">
                                    <SkeletonTheme
                                      color="grey"
                                      highlightColor="#444"
                                    >
                                      <p>
                                        <Skeleton
                                          height={20}
                                          width={20}
                                          count={1}
                                        />
                                      </p>
                                    </SkeletonTheme>
                                    <Skeleton className="mb-0" />
                                  </li>
                                </ul>
                                <ul className="custom-list mt-2">
                                  <li className="custom-list-item d-flex">
                                    <SkeletonTheme
                                      color="grey"
                                      highlightColor="#444"
                                    >
                                      <p>
                                        <Skeleton
                                          height={20}
                                          width={20}
                                          count={1}
                                        />
                                      </p>
                                    </SkeletonTheme>
                                    <Skeleton className="mb-0" />
                                  </li>
                                </ul>
                                <ul className="custom-list mt-2">
                                  <li className="custom-list-item d-flex">
                                    <SkeletonTheme
                                      color="grey"
                                      highlightColor="#444"
                                    >
                                      <p>
                                        <Skeleton
                                          height={20}
                                          width={20}
                                          count={1}
                                        />
                                      </p>
                                    </SkeletonTheme>
                                    <p className="mb-0">
                                      <Skeleton />
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    </section>
  );
};

export default About;

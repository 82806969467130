import React from "react";
import TextButton from "../../../templates/component/buttons/textButton";

const ChairpersonAddButton = () =>{

    return(
        <>
            <TextButton />

        </>
    )
}

export default ChairpersonAddButton;
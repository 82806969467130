import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetMembersQuery,
  useGetMembersByIdQuery,
  useAddMemberMutation,
  useUpdateMemberMutation,
  useDeleteMemberMutation,
} from "../../../../services/memberSlice";
import MemberAddButton from "./memberAddButton";
import { Box } from "@mui/system";
import usePagination from "../../component/pagination";

const DashboardMembers = () => {
  return <DashboardMembersPages />;
};

const DashboardMembersPages = () => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const { data, isLoading, isSuccess, isError, error } =
    useGetMembersQuery("getMembers");

  

  const [deleteMember] = useDeleteMemberMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    deleteMember({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Full Name", "Email", "Class of Batch", "Address", "Is Active"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Full Name":
          data.entities[key].first_name +
          " " +
          data.entities[key].middle_name +
          " " +
          data.entities[key].last_name,
        Email: data.entities[key].email,
        id: data.entities[key].id,
        "Class of Batch": data.entities[key].class_of_batch,
        Address: data.entities[key].city,
        "Is Active": data.entities[key].is_active,
      });
    });
  }
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  
  

  const count = Math.ceil(tableData.length / PER_PAGE);

  const _DATA = usePagination(tableData, count, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Member Request
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={_DATA.currentData()}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"member"}
            deleteConfirm={deleteConfirm}
            activeSign={["Is Active"]}
          />
          <Box style={{ marginTop: "20px", textAlign: "end" }}>
            <Stack spacing={2}>
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashboardMembers;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetDonationInformationsByIdQuery } from "../../../../services/donationInformationSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateDonationInformationMutation } from "../../../../services/donationInformationSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const DonationInformationEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditDonationInformationPages />
      </Grid>
    </Grid>
  );
};

const EditDonationInformationPages = () => {
  const { id } = useParams();
  
  const DonationInformations = useGetDonationInformationsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateDonationInformation, setUpdateDonationInformation] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    bank_information: "",
    esewa_information: "",
    account_name: "",
    account_number: "",
    bank_name: "",
    swift_code: "",
    address: ""
  });

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (DonationInformations.data) {
      setInitialFValues({
        bank_information: DonationInformations.data.bank_information,
        esewa_information: DonationInformations.data.esewa_information,
        account_name: DonationInformations.data.account_name,
        account_number: DonationInformations.data.account_number,
        bank_name: DonationInformations.data.bank_name,
        swift_code: DonationInformations.data.swift_code,
        address: DonationInformations.data.address
      });
    }
  }, [DonationInformations.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editDonationInformation] = useUpdateDonationInformationMutation();


  

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData()
    if(values.bank_information)
      formData.append("bank_information", values.bank_information);
    if(values.esewa_information)
      formData.append("esewa_information", values.esewa_information);
    if(values.account_name)
      {
        formData.append("account_name", values.account_name)
      }
    if(values.account_number)
      {
        formData.append("account_number", values.account_number)
      }
    if(values.bank_name)
      {
        formData.append("bank_name", values.bank_name)
      }
    if(values.swift_code)
      {
        formData.append("swift_code", values.swift_code)
      }
    if(values.address)
      {
        formData.append("address", values.address)
      }
    formData.append("id", id);
    editDonationInformation(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated DonationInformations Data.");
        setTimeout(() => {
          history("/dashboard/donation-information");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update DonationInformation List
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="bank_information"
                label="Bank Information"
                value={values.bank_information}
                onChange={handleInputChange}
              />

            <Controls.Input
                name="esewa_information"
                label="esewa Information"
                value={values.esewa_information}
                onChange={handleInputChange}
              />
            
            <Controls.Input
                name="account_name"
                label="Account Name"
                value={values.account_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="account_number"
                label="Account Number"
                value={values.account_number}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="bank_name"
                label="Bank Name"
                value={values.bank_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="swift_code"
                label="Swift Code"
                value={values.swift_code}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="address"
                label="address"
                value={values.address}
                onChange={handleInputChange}
              />

            </Grid>
          </Grid>
          <Grid container>
          <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
            </Grid>

        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DonationInformationEditPage;

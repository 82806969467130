import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";

const Four_Gallery = ({ image }) => {
  return (
    <>
      <Grid item md={6} xs={12}>
        <Box style={{ marginBottom: "0px" }}>
          <img
            src={image[0]}
            className="images-hover-design-gallery"
            style={{ width: "100%", height: "300px", borderRadius: "10px" }}
          />
        </Box>
        <Box style={{ marginBottom: "0px" }}>
          <img
            src={image[1]}
            className="images-hover-design-gallery"
            style={{ width: "100%", height: "300px", borderRadius: "10px" }}
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box style={{ marginBottom: "0px" }}>
          <img
            src={image[2]}
            className="images-hover-design-gallery"
            style={{ width: "100%", height: "300px", borderRadius: "10px" }}
          />
        </Box>
        <Box style={{ marginBottom: "0px" }}>
          <img
            src={image[3]}
            className="images-hover-design-gallery"
            style={{ width: "100%", height: "300px", borderRadius: "10px" }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default Four_Gallery;

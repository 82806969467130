import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const worksAdapter = createEntityAdapter()

const initialState = worksAdapter.getInitialState()

export const workSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWorks: builder.query({
            query: () => '/basic/work/',
            transformResponse: responseData => {
                return worksAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Works', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Works', id }))
            ]
        }),
        getWorksById: builder.query({
            query: (id)=>({

                url:`/basic/work/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Works']
        }),
        addWork: builder.mutation({
            query: (work) => {
            return{
                url: '/basic/work/',
                method: 'POST',
                body: work,
            }
        },
            invalidatesTags: ['Works']
        }),
        updateWork: builder.mutation({
           
            query: (updateWork) => {
                return{
                url: `/basic/work/${updateWork.get('id')}/`,
                method: 'PATCH',
                body: updateWork
                }
            },
            invalidatesTags: ['Works']
        }),
        deleteWork: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/basic/work/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Works']
        }),
    })
})

export const {
    useGetWorksQuery,
    useAddWorkMutation,
    useUpdateWorkMutation,
    useDeleteWorkMutation,
    useGetWorksByIdQuery
} = workSlice

// returns the query result object
export const selectWorksResult = workSlice.endpoints.getWorks.select()

// Creates memoized selector
const selectWorkssData = createSelector(
    selectWorksResult,
    worksResult => worksResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllWorks,
    selectById: selectWorkById,
    selectIds: selectWorkIds
    // Pass in a selector that returns the posts slice of state
} = worksAdapter.getSelectors(state => selectWorkssData(state) ?? initialState)
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetContactsQuery,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useAddContactMutation,
} from "../../../../services/contactSlice";
import ContactAddButton from "./contactAddButton";
import { Box } from "@mui/system";

const DashboardContacts = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardContactsPages />
      </Grid>
    </Grid>
  );
};

const DashboardContactsPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetContactsQuery("getContacts");

  const [deleteContact] = useDeleteContactMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    
    deleteContact({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Full Name", "Email", "Replied", 'Contact No'];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Full Name": data.entities[key].full_name,
        "Email": data.entities[key].email,
        id: data.entities[key].id,
        "Replied": data.entities[key].is_replied,
        "Contact No": data.entities[key].contact_no,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">Contact Page Details</Typography>
          </Grid>
          <Grid item xs={6}>
          
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"contact"}
            deleteConfirm={deleteConfirm}
            activeSign={["Replied"]}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardContacts;

import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Logo from "../../../static/images/logo.png";

const Header = () => {
  const Access_token = Cookies.get("access");
  const Refresh_token = Cookies.get("refresh");
  const email = Cookies.get("username");
  const is_admin = Cookies.get("is_admin");
  const TOKEN_KEY = { Access_token, Refresh_token, email, is_admin };
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (
      TOKEN_KEY["access"] == undefined &&
      TOKEN_KEY["refresh"] == undefined &&
      TOKEN_KEY["username"] == undefined &&
      TOKEN_KEY["is_admin"] == undefined
    ) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, []);

  const history = useNavigate();
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const Logout = (e) => {
    e.preventDefault();
    try {
      Cookies.remove("access");
      Cookies.remove("refresh");
      Cookies.remove("username");
      Cookies.remove("is_admin");
      setSuccesMessege("Successfully Has Been Logout.");
      history("/");
      setTimeout(() => {
        window.location.reload(true);
      }, [2000]);
    } catch (err) {
      
      setErrorMessege("Some Error Occur. Please Try again later.");
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };
  const [show,setShow] = useState(false);
  return (
    <>
      <header className="site-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 d-flex flex-wrap">
              <p className="d-flex me-4 mb-0">
                <i className="bi-geo-alt me-2"></i>
                Kalimpong Complex, Boudha-6, Kathmandu
              </p>

              <p className="d-flex mb-0">
                <i className="bi-envelope me-2"></i>

                <a href="mailto:namgyalgokarnaalumni@gmail.com">
                  namgyalgokarnaalumni@gmail.com
                </a>
              </p>
            </div>

            <div className="col-lg-3 col-12 ms-auto d-lg-block d-none">
              <ul className="social-icon">
                <li className="social-icon-item">
                  <a
                    href="https://twitter.com/search?q=Namgyal%20Gokarna%20Alumni%20Association&src=typed_query"
                    target="_blank"
                    className="social-icon-link bi-twitter"
                  ></a>
                </li>

                <li className="social-icon-item">
                  <a
                    href="https://www.facebook.com/namgyalgokarnaalumni"
                    target="_blank"
                    className="social-icon-link bi-facebook"
                  ></a>
                </li>

                <li className="social-icon-item">
                  <a href="#"  className="social-icon-link bi-instagram"></a>
                </li>

                <li className="social-icon-item">
                  <a href="#" className="social-icon-link bi-youtube"></a>
                </li>

                <li className="social-icon-item">
                  <a href="https://api.whatsapp.com/send/?phone=9779841211045" target="_blank"  className="social-icon-link bi-whatsapp"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <nav className="navbar navbar-expand-lg bg-light shadow-lg">
        <div className="container-fluid" >
          <Link className="navbar-brand" to="/">
            <img
              src={Logo}
              className="logo img-fluid"
              alt="Namgyal Gokarna Alumni Association"
            />
            <span
              className="my-res-data"
              style={{ marginLeft: 10, fontSize: 16, marginBottom: 9 }}
            >
              Namgyal Gokarna <br/> Alumni Association
              <small style={{ color: "black",marginTop: 5 }}>Nepal</small>
            </span>
          </Link>

          <button
            className="navbar-toggler"
            data-bs-toggle="collapse"
            href="#navbarCollapse"
            role="button"
            aria-expanded={show===true?"true":"false"}
            aria-controls="navbarCollapse"
            onClick={!show===true?()=>setShow(true):()=>setShow(false)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={show===true?"collapse navbar-collapse show":"collapse navbar-collapse res-display"} id="navbarCollapse ">
            <ul className="navbar-nav ms-auto nayaclass">
              <li className="nav-item">
                <Link className="nav-link click-scroll" to="/">
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <HashLink className="nav-link click-scroll" to="/#section_2">
                  About Us
                </HashLink>
              </li>

              {/* <li className="nav-item">
                <HashLink className="nav-link click-scroll" to="#section_3">
                  Service
                </HashLink>
              </li> */}

              {!isAuth === true ? null : (
                <li className="nav-item">
                  <Link className="nav-link click-scroll" to="/become/a/member">
                    Member
                  </Link>
                </li>
              )}

              <li className="nav-item dropdown">
                <Link
                  className="nav-link click-scroll"
                  to="/blog"
                  //   id="navbarLightDropdownMenuLink"
                  //   role="button"
                  //   data-bs-toggle="dropdown"
                  //   aria-expanded="false"
                >
                  News & Activities
                </Link>
              </li>

              <li className="nav-item">
                <HashLink className="nav-link click-scroll" to="/#section_6">
                  Contact
                </HashLink>
              </li>
              <li className="nav-item">
                <Link className="nav-link click-scroll" to="/gallery">
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link click-scroll" to="/donation">
                  Donation
                </Link>
              </li>

              {isAuth === true ? (
                <li className="nav-item ms-3">
                  <button
                    className="nav-link custom-btn custom-border-btn btn"
                    onClick={Logout}
                  >
                    Logout
                  </button>
                </li>
              ) : (
                <li className="nav-item ms-3">
                  <Link
                    className="nav-link custom-btn custom-border-btn btn"
                    to="/login"
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {SuccessMessege === null ? null : (
            <Box className="successmessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                {SuccessMessege}
              </Typography>
            </Box>
          )}
          {ErrorMessege === null ? null : (
            <Box className="errormessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="messegeicon"
                />
                {ErrorMessege}
              </Typography>
            </Box>
          )}
        </div>
      </nav>
    </>
  );
};

export default Header;

import React from "react";
import { useGetTestimonialsQuery } from "../../../../services/testimonialSlice";

const Testomonial = () => {
  const data = useGetTestimonialsQuery("getTestimonials");
  return (
    <section className="testimonial-section section-padding section-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12 mx-auto">
            <h2 className="mb-lg-3" style={{color:'white'}}> Testimonial</h2>

            <div
              id="testimonial-carousel"
              className="carousel carousel-fade slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {data !== undefined ? (
                  <>
                    {data.status === "fulfilled" ? (
                      <>
                        {data.data.ids.map((key) => (
                          <div
                            className={
                              data.data.ids[0]
                                ? "carousel-item active"
                                : "carousel-item"
                            }
                          >
                            <div className="carousel-caption">
                              <h4 className="carousel-title">
                                {data.data.entities[key].client_testimonial}
                              </h4>

                              <small className="carousel-name">
                                <span className="carousel-name-title">
                                  {data.data.entities[key].client_name}
                                </span>
                              </small>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}

                <ol className="carousel-indicators">
                  {data !== undefined ? (
                    <>
                      {data.status === "fulfilled" ? (
                        <>
                          {data.data.ids.map((key, index) => (
                            <li
                              data-bs-target="#testimonial-carousel"
                              data-bs-slide-to={index}
                              className={
                                data.data.ids[0]
                                  ? "active"
                                  : ""
                              }
                            >
                              <img
                                src={data.data.entities[key].client_photo}
                                className="img-fluid rounded-circle avatar-image"
                                alt="avatar"
                              />
                            </li>
                          ))}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testomonial;

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { HashLink } from "react-router-hash-link";
import Logo from "../../../static/images/logo.png";

const Footer = () => {
  let newDate = new Date();
  let date = newDate.getFullYear();
  return (
    <footer className="site-footer" style={{ textAlign: "left" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-12 mb-4">
            <img src={Logo} className="logo img-fluid" alt="" />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mb-4">
            <h5 className="site-footer-title mb-3" style={{color:"white"}}>Quick Links</h5>

            <ul className="footer-menu">
              <li className="footer-menu-item">
                <HashLink to="/#section_2" className="footer-menu-link">
                  About Us
                </HashLink>
              </li>

              <li className="footer-menu-item">
                <a href="/become/a/member" className="footer-menu-link">
                 Become a Member
                </a>
              </li>

              <li className="footer-menu-item">
                <a href="/blog" className="footer-menu-link">
                  Latest News
                </a>
              </li>

              <li className="footer-menu-item">
                <a href="/donation" className="footer-menu-link">
                  Donation
                </a>
              </li>

              <li className="footer-menu-item">
                <HashLink to="/#section_6" className="footer-menu-link">
                  Contact us
                </HashLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mx-auto">
            <h5 className="site-footer-title mb-3" style={{color:"white"}}>Contact Infomation</h5>

            <p className="text-white d-flex mb-2">
              <i className="bi-telephone me-2"></i>

              <a href="tel: 9841211045" className="site-footer-link">
              +977-9841211045, +977-9802034226
              </a>
            </p>

            <p className="text-white d-flex">
              <i className="bi-envelope me-2"></i>

              <a href="mailto:namgyalgokarnaalumni@gmail.com" className="site-footer-link">
              namgyalgokarnaalumni@gmail.com
              </a>
            </p>

            <p className="text-white d-flex mt-3">
              <i className="bi-geo-alt me-2"></i>
              Kalimpong Complex, Boudha-6, Kathmandu
            </p>

            <a href="https://www.google.com/maps/place/Kalimpong+Complex/@27.723551,85.3616514,17z/data=!4m12!1m6!3m5!1s0x39eb1b26fe72cce1:0x1087ee5aaf280dfd!2sKalimpong+Complex!8m2!3d27.723551!4d85.3642263!3m4!1s0x39eb1b26fe72cce1:0x1087ee5aaf280dfd!8m2!3d27.723551!4d85.3642263" target="_blank" className="custom-btn btn mt-3">
              Get Direction
            </a>
          </div>
        </div>
      </div>

      <div className="site-footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-7 col-12">
              <p className="copyright-text mb-0" >
                Copyright © {date}{" "}
                <a style={{'color': '#fff'}} href="#" >
                  Namgyal Gokarna Alumni
                </a>{" "}
                Design & Developed by{" "}
                <a style={{'color': '#fff'}} 
                  href="https://www.developergd.com/"
                  
                  target="_blank"
                >
                  G. & D. Developers
                </a>
              </p>
            </div>

            <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
              <ul className="social-icon">
                <li className="social-icon-item">
                  <a
                    href="https://twitter.com/search?q=Namgyal%20Gokarna%20Alumni%20Association&src=typed_query"
                    target="_blanck"
                    className="social-icon-link bi-twitter"
                  ></a>
                </li>

                <li className="social-icon-item">
                  <a
                    href="https://www.facebook.com/namgyalgokarnaalumni"
                    target="_blanck"
                    className="social-icon-link bi-facebook"
                  ></a>
                </li>

                <li className="social-icon-item">
                  <a href="#" className="social-icon-link bi-instagram"></a>
                </li>

                <li className="social-icon-item">
                  <a href="https://api.whatsapp.com/send/?phone=9779841211045" target="_blank" className="social-icon-link bi-whatsapp"></a>
                </li>

                <li className="social-icon-item">
                  <a
                    href="https://www.developergd.com/"
                    className="social-icon-link bi-youtube"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

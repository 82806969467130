import React from "react";
import { Grid } from "@mui/material";
import {Box} from "@mui/material";

const Double_Gallery = ({image}) => {


return(
    <>
<Grid item md={6} xs={12}>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[0]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "300px",borderRadius: "10px" }}
      />
    </Box>
  </Grid>
  <Grid item md={6} xs={12}>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[1]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "300px",borderRadius: '10px' }}
      />
    </Box>
  </Grid>
</>
)

}

export default Double_Gallery;
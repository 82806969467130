import Pagination from "react-mui-pagination";
import { useState } from "react";

function PaginationComponent() {
  // 
  const [page, setMyPage] = useState([]); // this an example using hooks
  const setPage = () => {
    setMyPage();
  };
  const thisClick = (e) =>{
    
  }
  return (
    <Pagination numOfLinks={6} page={page} setPage={setPage} total={100} onClick={thisClick}/>
  );
}

export default PaginationComponent;
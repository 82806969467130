import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../services/constants";
import Footer from "../../component/footer";
import Header from "../../component/header";

const NewPassword = () => {
  return (
    <>
      <Header />
      <NewPasswordPage />
      <Footer />
    </>
  );
};

const NewPasswordPage = () => {
  const [password, setPassword] = useState({
    password:"",
    c_password:""
});
  const data =window.location.href;
  const url = new URL(data);
  const base64 = url.searchParams.get("uidb64");
  const token = url.searchParams.get("token");
  
  const onPassword = (e) =>
    setPassword((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  

  const myRoute = useNavigate();
  const onChangePassword = (e) => {
    if (password.password === password.c_password) {
      axios
        .patch(`${BASE_URL}/user/set-new-password/`,{"uidb64":base64, "token":token, "password": password.password})
        .then((res) => {
          setSuccesMessege("Your Password has been Successfully Changed.");
          setTimeout(() => {
            myRoute("/login");
          }, [3000]);
        })
        .catch((err) => {
          setErrorMessege("Some Error Occur While Changing Password.");
          setTimeout(() => {
            setErrorMessege(null);
          }, [3000]);
        });
    } else {
      setErrorMessege("Password Doesnot Match.");
      setTimeout(() => {
        setErrorMessege(null);
      }, [3000]);
    }
  };
  return (
    <Box className="signinmargin">
      <Grid
        item
        md={6}
        xs={12}
        style={{ margin: "0 auto" }}
        className="signinauto"
      >
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
            className="signinleft"
            style={{ textAlign: "start" }}
          >
            <Box>
              <Typography className="signintext">
                Enter your new password
              </Typography>
            </Box>
            <Box className="newforms">
              <Box>
                <label className="emailtext">New Password*</label>
                <input
                  type="password"
                  name="password"
                  value={password.password}
                  onChange={onPassword}
                  className="titleform2"
                  placeholder="Enter Your New Password"
                />
              </Box>
              <Box className="passwordbtn">
                <label className="emailtext">Confirm Password*</label>
                <input
                  type="password"
                  name="c_password"
                  onChange={onPassword}
                  value={password.c_password}
                  className="titleform2"
                  placeholder="Enter Same Password"
                />
              </Box>
              <button
                className="signinbutton"
                onClick={onChangePassword}
                style={{ border: "none" }}
              >
                Submit
              </button>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className="leftcolor display-none">
            <Box className="signinnayamargin4">
              <Typography className="accounttext">
                Already Have an Account?
              </Typography>
              <Link to="/login">
                <button className="signupnewbtn">Login</button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {SuccessMessege === null ? null : (
        <Box className="successmessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
            {SuccessMessege}
          </Typography>
        </Box>
      )}
      {ErrorMessege === null ? null : (
        <Box className="errormessege">
          <Typography className="messegefonts">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="messegeicon"
            />
            {ErrorMessege}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default NewPassword;

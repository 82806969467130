import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useGetDonationInformationsActiveQuery } from "../../../services/donationInformationSlice";
import Footer from "../component/footer";
import Header from "../component/header";

const Donation = () => {
  return (
    <Box>
      <Header />
      <Donation_page />
      <Footer />
    </Box>
  );
};

const Donation_page = () => {
  const data = useGetDonationInformationsActiveQuery("getDonations");

  

  
  return (
    <main>
      <section className="donate-section">
        <div className="section-overlay"></div>
        <div className="container">
          <div className="row">
            {data !== undefined ? (
              <>
                <>
                  {data.status === "fulfilled" ? (
                    <div className="col-lg-6 col-12 mx-auto">
                      {data.data.map((key) => (
                        <form
                          className="custom-form donate-form"
                          action="#"
                          method="get"
                          role="form"
                        >
                          <h3 className="mb-4">Make a donation</h3>

                          <div style={{ textAlign: "start" }}>
                            <h5 className="mb-3">Bank Informartion</h5>
                            <div>
                              {/* <p className="mb-2">
                                Bank Information: {key.bank_information}
                              </p>
                              <p className="mb-2">
                                Online Information: {key.esewa_information}
                              </p> */}
                              <p className="mb-2">
                                Account Name: {key.account_name}
                              </p>
                              <p className="mb-2">
                                Account Number: {key.account_number}
                              </p>
                              <p className="mb-2">
                                Bank Name: {key.bank_name}
                              </p>
                              <p className="mb-2">
                                Swift Code: {key.swift_code}
                              </p>
                              <p className="mb-2">
                                Address: {key.address}
                              </p>
                            </div>
                          </div>
                        </form>
                      ))}
                    </div>
                  ) : (
                    <div className="col-lg-6 col-12 mx-auto">
                      <SkeletonTheme color="grey" highlightColor="#444">
                        <p>
                          <Skeleton height={300} width={"100%"} count={1} />
                        </p>
                      </SkeletonTheme>
                    </div>
                  )}
                </>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Donation;

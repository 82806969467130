import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Logo from "../../../static/images/logo.png";
import { FaUserCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { FiMonitor } from "react-icons/fi";
import {AiOutlineHome} from "react-icons/ai";
import {MdOutlineMiscellaneousServices} from "react-icons/md";
import {RiProductHuntLine} from "react-icons/ri";
import {GiNewspaper} from "react-icons/gi"
import {TfiGallery} from "react-icons/tfi";
import {FcAbout} from "react-icons/fc";
import {FaBlogger} from "react-icons/fa";
import '../../../static/css/dashboard.css'

const Sidebar = () => {
  const [Show,setShow] = useState(true);
  
  return (
    <Box className="dashboard-side-bar-color">
      <Box>
        <Box style={{padding:'.8rem'}}>
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <FaUserCircle size={30} color="white" />
            </Grid>
            <Grid item xs={10}>
              <Typography className="admin-dashboard-text">
                Admin Dashboard
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: ".7rem" }}>
          <List>
            <ListItem className={Show==='true'?"dashboard-side-bar-link":"dashboard-side-bar-link1"} onClick={()=>setShow('true')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/home"
              >
                <FiMonitor
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Dashboard
              </Link>
            </ListItem>
            <ListItem className={Show==='home'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('home')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/home-pages"
              >
                <AiOutlineHome
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Home Page
              </Link>
            </ListItem>
            <ListItem className={Show==='about'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('about')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/about-pages"
              >
                <FcAbout
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                About Page
              </Link>
            </ListItem>

            <ListItem className={Show==='product'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('product')} >
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/work"
              >
                <RiProductHuntLine
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Works
              </Link>
            </ListItem>
            <ListItem className={Show==='product'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('product')} >
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/mission"
              >
                <RiProductHuntLine
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Missions
              </Link>
            </ListItem>
            <ListItem className={Show==='product'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('product')} >
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/vision"
              >
                <RiProductHuntLine
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Visions
              </Link>
            </ListItem>
            <ListItem className={Show==='doctors'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('doctors')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/chairperson"
              >
                <FaBlogger
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Chairperson
              </Link>
            </ListItem>
            
            <ListItem className={Show==='blog'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('blog')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/blogs"
              >
                <FaBlogger
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                News & Activities
              </Link>
            </ListItem>
            <ListItem className={Show==='services'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('services')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/testimonial"
              >
                <MdOutlineMiscellaneousServices
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Testimonial
              </Link>
            </ListItem>
            <ListItem className={Show==='contact'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('contact')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/contact"
              >
                <GiNewspaper
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Contact
              </Link>
            </ListItem>

            <ListItem className={Show==='gallery'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('gallery')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/image-gallerys"
              >
                <TfiGallery
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Gallery
              </Link>
            </ListItem>

            <ListItem className={Show==='doctors'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('doctors')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/donation-information"
              >
                <FaBlogger
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Donation
              </Link>
            </ListItem>
        

            <ListItem className={Show==='doctors'?"dashboard-side-bar-link":"dashboard-side-bar-link1"}  onClick={()=>setShow('doctors')}>
              <Link
                className="dashboard-side-bar-link-text"
                to="/dashboard/constantvalue"
              >
                <FaBlogger
                  style={{
                    marginRight: "10px",
                    position: "relative",
                    top: "3px",
                  }}
                />
                Constant Values
              </Link>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;

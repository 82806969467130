import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Icon1 from "../../../static/images/icons/hands.png";
import Icon2 from "../../../static/images/icons/heart.png";
import Icon3 from "../../../static/images/icons/receive.png";
import Icon4 from "../../../static/images/icons/scholarship.png";
import Charity from "../../../static/images/portrait-volunteer-who-organized-donations-charity.jpg";
import Kids from "../../../static/images/causes/group-african-kids-paying-attention-class.jpg";
import TShirt from "../../../static/images/avatar/pretty-blonde-woman-wearing-white-t-shirt.jpg";
import TShirt1 from "../../../static/images/avatar/secretary.jpg";
import ImageSlider from "./homepages/imageSlider";
import About from "./homepages/about";
import { HashLink } from "react-router-hash-link";
import Blog from "./homepages/blog";
import Testomonial from "./homepages/testomonial";
import { Link } from "react-router-dom";
import ContactForm from "./homepages/contact";
import { useGetChairpersonsActiveQuery } from "../../../services/chairpersonSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Home = () => {
  return (
    <Box>
      <Header />
      <HomePage />
      <Footer />
    </Box>
  );
};

const HomePage = () => {
  const data = useGetChairpersonsActiveQuery("getChairpersons");
  
  return (
    <main style={{ textAlign: "start" }}>
      {data.status === "fulfilled" ? (
        <ImageSlider />
      ) : (
        <SkeletonTheme color="grey" highlightColor="#444">
          <p>
            <Skeleton height={680} width={'100%'} count={1} />
          </p>
        </SkeletonTheme>
      )}
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 text-center mx-auto">
              <h2 className="mb-5">Welcome to Kind Heart Charity</h2>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <Link
                  to="/become/a/member"
                  className="d-block"
                  style={{ color: "white" }}
                >
                  <img
                    src={Icon1}
                    className="featured-block-image img-fluid"
                    alt=""
                  />

                  <p
                    className="featured-block-text"
                    style={{ color: "white !important" }}
                  >
                    Become a <strong>Member</strong>
                  </p>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <Link to="/gallery" className="d-block">
                  <img
                    src={Icon2}
                    className="featured-block-image img-fluid"
                    alt=""
                  />
                  <p className="featured-block-text">
                    <strong>Caring</strong> People
                  </p>
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <Link to="/donation" className="d-block">
                  <img
                    src={Icon3}
                    className="featured-block-image img-fluid"
                    alt=""
                  />

                  <p className="featured-block-text">
                    Make a <strong>Donation</strong>
                  </p>
                </Link>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <a href="/donation" className="d-block">
                  <img
                    src={Icon4}
                    className="featured-block-image img-fluid"
                    alt=""
                  />

                  <p className="featured-block-text">
                    <strong>Scholarship</strong> Program
                  </p>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <About />
      <section className="about-section section-padding">
        <div className="container">
          {data !== undefined ? (
            <>
              {data.status === "fulfilled" ? (
                <>
                  {data.data.ids.map((key) => (
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-12">
                        <img
                          src={data.data.entities[key].image}
                          className="about-image ms-lg-auto bg-light shadow-lg img-fluid"
                          alt=""
                        />
                      </div>

                      <div className="col-lg-8 col-md-8 col-12">
                        <div className="">
                          <h2 className="mb-0">
                            {data.data.entities[key].name}
                          </h2>

                          <p className="text-muted mb-lg-4 mb-md-4 mt-lg-3">
                            Message from President
                          </p>

                          <p style={{textAlign:'justify',fontWeight: '500'}}>{data.data.entities[key].message}</p>

                          <ul className="social-icon mt-4">
                            <li className="social-icon-item">
                              <a
                                href="#"
                                className="social-icon-link bi-twitter font-color-green"
                              ></a>
                            </li>

                            <li className="social-icon-item">
                              <a
                                href="#"
                                className="social-icon-link bi-facebook"
                              ></a>
                            </li>

                            <li className="social-icon-item">
                              <a
                                href="#"
                                className="social-icon-link bi-instagram"
                              ></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-6 col-md-5 col-12" >
                      <SkeletonTheme color="grey" highlightColor="#444" >
                        <p>
                          <Skeleton height={420} width={350} count={1} />
                        </p>
                      </SkeletonTheme>
                    </div>

                    <div className="col-lg-5 col-md-7 col-12">
                      <div className="custom-text-block">
                        <h2 className="mb-0">
                          <Skeleton />
                        </h2>

                        <p className="text-muted mb-lg-4 mb-md-4">
                          Message from President
                        </p>

                        <p>
                          <Skeleton />
                        </p>
                        <p>
                          <Skeleton />
                        </p>
                        <p>
                          <Skeleton />
                        </p>
                        <p>
                          <Skeleton />
                        </p>

                        <ul className="social-icon mt-4">
                          <li className="social-icon-item">
                            <a
                              href="#"
                              className="social-icon-link bi-twitter"
                            ></a>
                          </li>

                          <li className="social-icon-item">
                            <a
                              href="#"
                              className="social-icon-link bi-facebook"
                            ></a>
                          </li>

                          <li className="social-icon-item">
                            <a
                              href="#"
                              className="social-icon-link bi-instagram"
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : null}
        </div>
      </section>

      <section className="cta-section section-padding section-bg">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-12 ms-auto">
              <h2 className="mb-0" style={{ color: "white" }}>
                Make an impact. <br /> Stay Intact.
              </h2>
            </div>

            <div className="col-lg-5 col-12">
              <a href="/donation" className="me-4">
                Make a donation
              </a>

              <Link
                to="/become/a/member"
                className="custom-btn btn smoothscroll"
              >
                Become a Member
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-padding" id="section_3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 text-center mb-4">
              <h2>Our Services</h2>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="custom-block-wrap">
                <img
                  src={Kids}
                  className="custom-block-image img-fluid"
                  alt=""
                />

                <div className="custom-block">
                  <div className="custom-block-body">
                    <h5 className="mb-3">Children Education</h5>

                    <p>
                      Lorem Ipsum dolor sit amet, consectetur adipsicing kengan
                      omeg kohm tokito
                    </p>
                  </div>

                  <a href="donate.html" className="custom-btn btn">
                    Donate now
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="custom-block-wrap">
                <img
                  src={Kids}
                  className="custom-block-image img-fluid"
                  alt=""
                />

                <div className="custom-block">
                  <div className="custom-block-body">
                    <h5 className="mb-3">Poverty Development</h5>

                    <p>
                      Sed leo nisl, posuere at molestie ac, suscipit auctor
                      mauris. Etiam quis metus tempor
                    </p>
                  </div>

                  <a href="donate.html" className="custom-btn btn">
                    Donate now
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="custom-block-wrap">
                <img
                  src={Kids}
                  className="custom-block-image img-fluid"
                  alt=""
                />

                <div className="custom-block">
                  <div className="custom-block-body">
                    <h5 className="mb-3">Supply drinking water</h5>

                    <p>
                      Orci varius natoque penatibus et magnis dis parturient
                      montes, nascetur ridiculus
                    </p>
                  </div>

                  <a href="donate.html" className="custom-btn btn">
                    Donate now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Blog />
      <Testomonial />

      <section className="contact-section section-padding" id="section_6">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 ms-auto mb-5 mb-lg-0">
              <div className="contact-info-wrap">
                <h2>Get in touch</h2>

                <div className="contact-image-wrap d-flex flex-wrap">
                  <img
                    src={TShirt1}
                    className="img-fluid avatar-image"
                    alt=""
                  />

                  <div className="d-flex flex-column justify-content-center ms-3">
                    <p className="mb-0"> Kelsang Tsomo</p>
                    <p className="mb-0">
                      <strong>Secretary</strong>
                    </p>
                  </div>
                </div>

                <div className="contact-info">
                  <h5 className="mb-3">Contact Infomation</h5>

                  <p className="d-flex mb-2" style={{ color: "black" }}>
                    <i className="bi-geo-alt me-2"></i>
                    Kalimpong Complex, Boudha-6, Kathmandu
                  </p>

                  <p className="d-flex mb-2">
                    <i className="bi-telephone me-2"></i>

                    <a href="tel: +977 9865707226" style={{ color: "black" }}>
                    +977 9865707226, +977 985-1084886
                    </a>
                  </p>

                  <p className="d-flex">
                    <i className="bi-envelope me-2"></i>

                    <a
                      href="mailto:namgyalgokarnaalumni@gmail.com"
                      style={{ color: "black" }}
                    >
                      namgyalgokarnaalumni@gmail.com
                    </a>
                  </p>

                  <a
                    href="https://www.google.com/maps/place/Kalimpong+Complex/@27.723551,85.3616514,17z/data=!4m12!1m6!3m5!1s0x39eb1b26fe72cce1:0x1087ee5aaf280dfd!2sKalimpong+Complex!8m2!3d27.723551!4d85.3642263!3m4!1s0x39eb1b26fe72cce1:0x1087ee5aaf280dfd!8m2!3d27.723551!4d85.3642263"
                    target="_blank"
                    className="custom-btn btn mt-3"
                  >
                    Get Direction
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-12 mx-auto">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetConstantvaluesByIdQuery } from "../../../../services/constantvalueSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateConstantvalueMutation } from "../../../../services/constantvalueSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ConstantvalueEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditConstantvaluePages />
      </Grid>
    </Grid>
  );
};

const EditConstantvaluePages = () => {
  const { id } = useParams();
  const Constantvalues = useGetConstantvaluesByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateConstantvalue, setUpdateConstantvalue] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    official_signature: [],
    membership_fee: 0.0,
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Constantvalues.data) {
      urlToFile(Constantvalues.data.official_signature, setFile);
      setInitialFValues({
        official_signature: File,
        membership_fee: Constantvalues.data.membership_fee,
      });
    }
  }, [Constantvalues.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editConstantvalue] = useUpdateConstantvalueMutation();

  useEffect(() => {
    handleImageUpload("official_signature", File);
  }, [File]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.official_signature, "constantvalue");
    if (file.length !== 0) {
      formData.append("official_signature", file, "constantvalue.jpg");
    }
    if(values.membership_fee)
      formData.append("membership_fee", values.membership_fee);
    formData.append("id", id);
    editConstantvalue(formData)
      .then((res) => {
        setSuccesMessege("Successfully Update Constantvalue Page Data.");
        setTimeout(() => {
          history("/dashboard/constantvalue");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Constant Value Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Controls.Input
                name="membership_fee"
                label="Membership Fee"
                value={values.membership_fee}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <label>Add official signature</label>
              <Controls.Image setFile={setFile} aspectRatio={368 / 246} />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConstantvalueEditPage;
